import fetch from "cross-fetch";
import qs from "qs";
import httpErrors from "http-errors";
import { retryWithBackoff } from "./promises";

export { HttpError } from "http-errors";

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export interface HttpOptions {
  headers: HttpHeaders;
  retryPost: boolean;
  maxRetries: number;
  minBackoff: number; // milliseconds
  maxBackoff: number; // milliseconds
  backoffMultiplier: number;
  retryable: (error: any) => boolean;
  contentType: "json" | "form";
  responseFormat: "json" | "text";
}

export interface HttpRequestOptions extends Partial<HttpOptions> {
  query?: Record<string, any>;
  body?: object | string;
}

export type HttpHeaders = Record<
  string,
  | string
  | ((options: HttpHeaderOptions) => string)
  | ((options: HttpHeaderOptions) => Promise<string>)
>;

export interface HttpHeaderOptions {
  method: string;
  path: string;
  query?: string;
  body?: string;
}

export interface HttpResponse<T> {
  data: T;
  headers: Headers;
}

/**
 * Fetch wrapper that supports back-off retries
 */
export class HttpClient {
  private readonly baseUrl: string;
  private readonly options: HttpOptions;

  constructor(
    baseUrl: string,
    options: Partial<HttpOptions> = {},
    private readonly makeRequest: typeof fetch = fetch
  ) {
    this.baseUrl = baseUrl.endsWith("/")
      ? baseUrl.slice(0, baseUrl.length - 1)
      : baseUrl;
    this.options = {
      headers: {},
      retryPost: false,
      maxRetries: 3,
      minBackoff: 1500,
      maxBackoff: 60000,
      backoffMultiplier: 1.5,
      retryable: (error: any) => {
        if (error instanceof httpErrors.HttpError) {
          return error.status >= 500 || error.status === 429;
        }

        // Defaults to retrying any unknown error types.
        return true;
      },
      contentType: "json",
      responseFormat: "json",
      ...options,
    };
  }

  request<T = any>(
    method: HttpMethod,
    path: string,
    options: HttpRequestOptions = {}
  ): Promise<HttpResponse<T>> {
    // construct the query portion
    const query = qs.stringify(options.query, { addQueryPrefix: true });

    // construct the request url
    const requestUrl =
      this.baseUrl + (path.startsWith("/") ? path : `/${path}`) + query;

    // combine the default options and provided
    const requestOptions = {
      ...this.options,
      ...options,
      headers: Object.assign({}, this.options.headers, options.headers),
    };

    return retryWithBackoff(
      async () => {
        // request headers
        const headers: Record<string, string> = {};
        let body: string | undefined;

        // include content type if we have a body
        if (options.body) {
          switch (requestOptions.contentType) {
            case "json": {
              headers["Content-Type"] = "application/json";
              body =
                typeof options.body === "string"
                  ? options.body
                  : JSON.stringify(options.body);
              break;
            }
            case "form": {
              headers["Content-Type"] = "application/x-www-form-urlencoded";
              body =
                typeof options.body === "string"
                  ? options.body
                  : qs.stringify(options.body, { format: "RFC3986" });
              break;
            }
          }
        }

        // combine default and explicit headers and resolve them
        const headerOptions: HttpHeaderOptions = {
          method,
          path,
          query,
          body,
        };
        await Promise.all(
          Object.entries(requestOptions.headers).map(async ([name, value]) => {
            headers[name] = await Promise.resolve(
              typeof value === "function" ? value(headerOptions) : value
            );
          })
        );

        // make the request
        const response = await this.makeRequest(requestUrl, {
          method,
          headers,
          body,
        });

        // process the response
        if (response.ok) {
          const text = await response.text();
          if (requestOptions.responseFormat === "json" && text) {
            return {
              data: JSON.parse(text) as T,
              headers: response.headers,
            };
          }
          return {
            data: text as any,
            headers: response.headers,
          };
        } else {
          const message = await response.text();
          throw new httpErrors[response.status](message || response.statusText);
        }
      },
      {
        ...requestOptions,
        retryable: (error: any) => {
          if (!requestOptions.retryPost && method === "POST") {
            return false;
          }
          return requestOptions.retryable(error);
        },
      }
    );
  }
}
