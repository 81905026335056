import { Robobot } from "../core";
import { isNonZeroValue, compareWeiStrings } from "./valueUtils";

export type RobobotFilter = {
  base: string;
  crown: string;
  ears: string;
  eyes: string;
  nose: string;
  mouth: string;
  neck: string;
  right_cheek: string;
  min_num_attributes: number;
  max_num_attributes: number;
  id: string;
  for_sale: boolean;
  min_price: string;
  max_price: string;
  owner: string;
  bidder: string;
  [key: string]: string | number | boolean;
};

export const blankRobobotFilter: Readonly<RobobotFilter> = {
  base: "",
  crown: "",
  eyes: "",
  nose: "",
  ears: "",
  mouth: "",
  neck: "",
  right_cheek: "",
  min_num_attributes: 0,
  max_num_attributes: 7,
  for_sale: false,
  min_price: "",
  max_price: "",
  id: "",
  owner: "",
  bidder: "",
};

export function filterRobobot(bot: Robobot, filter: RobobotFilter): boolean {
  const isForSale = (isNonZeroValue(bot.sale_price) || isNonZeroValue(bot.opensea_price));
  let finalPrice = "0";
  if (isNonZeroValue(bot.sale_price) && isNonZeroValue(bot.opensea_price)) {
    // take minimum
    finalPrice = compareWeiStrings(bot.sale_price as string, bot.opensea_price as string) < 0 ?
        bot.sale_price as string :
        bot.opensea_price as string;
  } else if (isNonZeroValue(bot.sale_price)) {
    finalPrice = bot.sale_price as string;
  } else if (isNonZeroValue(bot.opensea_price)) {
    finalPrice = bot.opensea_price as string;
  }

  return (
    (filter.base === "" || bot.base === filter?.base) &&
    (filter.crown === "" || bot.crown === filter?.crown) &&
    (filter.eyes === "" || bot.eyes === filter?.eyes) &&
    (filter.nose === "" || bot.nose === filter?.nose) &&
    (filter.ears === "" || bot.ears === filter?.ears) &&
    (filter.mouth === "" || bot.mouth === filter?.mouth) &&
    (filter.neck === "" || bot.neck === filter?.neck) &&
    (filter.right_cheek === "" || bot.right_cheek === filter?.right_cheek) &&
    (bot.num_attributes === undefined ||
      bot.num_attributes >= filter.min_num_attributes) &&
    (bot.num_attributes === undefined ||
      bot.num_attributes <= filter.max_num_attributes) &&
    (filter.for_sale === false || isForSale) &&
    (filter.min_price === "" || compareWeiStrings(finalPrice, filter.min_price) >= 0) &&
    (filter.max_price === "" || compareWeiStrings(finalPrice, filter.max_price) <= 0) &&
    (filter.id === "" || Number(bot.id) === Number(filter.id)) &&
    (filter.owner === "" || bot.owner?.toLowerCase() === filter?.owner.toLowerCase()) &&
    (filter.bidder === "" || bot.bidder?.toLowerCase() === filter?.bidder.toLowerCase())
  );
}

export function accountsEqual(account1: string | undefined | null, account2: string | undefined | null): boolean {
  if (!account1 || !account2) {
    return false;
  }
  return account1.toLowerCase() === account2.toLowerCase();
}
