import React from "react";
import { Robobot, Attributes, AttributeLocations, Adjustments } from "../core";
import { LoadedImageContext } from "./ImageContext";

function drawBackground(ctx: CanvasRenderingContext2D, sz: number) {
  ctx.fillStyle = "rgba(53, 53, 53, 1.0)";
  ctx.fillRect(0, 0, sz, sz);
}

function drawScaledImage(
  ctx: CanvasRenderingContext2D,
  imageData: Uint8ClampedArray | undefined,
  widthInPixels: number,
  pixelSize: number,
  adjustment?: number[]
) {
  if (!imageData || adjustment === undefined) {
    return;
  }
  if (
    imageData.length / 4 === widthInPixels * widthInPixels &&
    adjustment.length === 2
  ) {
    const [aX, aY] = adjustment;
    for (let x = 0; x < widthInPixels; x++) {
      for (let y = 0; y < widthInPixels; y++) {
        const idx = (x + y * widthInPixels) * 4;
        const r = imageData[idx + 0].toString();
        const g = imageData[idx + 1].toString();
        const b = imageData[idx + 2].toString();
        const a = (imageData[idx + 3] / 255.0).toFixed(2);
        let colorString = `rgba(${r}, ${g}, ${b}, ${a})`;
        ctx.fillStyle = colorString;
        ctx.fillRect(
          (x + aX) * pixelSize,
          (y + aY) * pixelSize,
          pixelSize,
          pixelSize
        );
      }
    }
  }
}

const getImageData = (robobot: Robobot | undefined, location: keyof Robobot) => {
  if (!robobot) return undefined;
  return Attributes.find((attr) => attr.name === robobot[location])?.data;
};

export const RoboImageCanvas: React.FC<{
  robobot?: Robobot;
  background?: boolean;
  pixelSize: number;
}> = ({ robobot, background, pixelSize }) => {

  const sourceWidth = 24; // pixels
  const canvasWidth = sourceWidth * pixelSize;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [canvasReady, setCanvasReady] = React.useState<boolean>(false);
  const loaded = React.useContext(LoadedImageContext);

  const drawImages = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, canvasWidth, canvasWidth); // clear canvas
      if (background) drawBackground(ctx, canvasWidth); // draw optional background
      if (!robobot) {
        console.error("attempted to draw undefined bot");
        return;
      }
      for (const location of AttributeLocations) {
        drawScaledImage(
          ctx,
          getImageData(robobot, location),
          sourceWidth,
          pixelSize,
          Adjustments[robobot.base][location]
        );
      }
    },
    [robobot, pixelSize, canvasWidth, background]
  );

  React.useLayoutEffect(() => {
    if (canvasRef.current) {
      setCanvasReady(true);
    }
  }, [canvasRef]);

  React.useEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx && robobot && canvasReady) {
      setTimeout(() => {
        drawImages(ctx);
      }, 100);
    }
  }, [robobot, drawImages, canvasRef, canvasReady, loaded]);

  return (
    <canvas
      ref={canvasRef}
      width={canvasWidth}
      height={canvasWidth}
      className="robo-canvas flex"
    />
  );
};
