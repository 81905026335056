import * as THREE from "three";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { BehaviorSubject } from "rxjs";
import { Robobot } from "../core";
import { OrbitControls } from "./OrbitControls";
import { ExploreController, RenderingSettings } from "./ExploreController";
import { SceneFactory } from "./ExploreContext";

export abstract class RobobotsScene {
  constructor(public readonly controller: ExploreController) {}

  abstract setData(data: any): any;
  abstract setup(data: any): any;
  dispose = () => {};
  update = () => {};
  draw = () => {};

  raycast(): THREE.Intersection[] {
    return [];
  }
}

const IMG_WIDTH = 2600.0;
const EXPLORE_SCENE_SETTINGS: RenderingSettings = {
  alwaysDraw: false,
  showStats: false,
};

export class ExploreScene extends RobobotsScene {
  composite: THREE.Texture;
  initialized: boolean;
  listenersActive: boolean;
  disposableObjects: THREE.Mesh[];
  raycaster: THREE.Raycaster;
  mouse: THREE.Vector2;
  hovered?: THREE.Object3D;
  selected: BehaviorSubject<THREE.Object3D | undefined>;

  lastTapTime: number;
  quickClickTimeout: number;

  renderPass!: RenderPass;
  outlinePass!: OutlinePass;

  constructor(controller: ExploreController) {
    super(controller);
    this.composite = new THREE.Texture();
    this.initialized = false;
    this.listenersActive = false;
    this.disposableObjects = [];
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    this.selected = new BehaviorSubject<THREE.Object3D | undefined>(undefined);

    this.lastTapTime = performance.now();
    this.quickClickTimeout = 250;
  }

  setData = (data: Robobot[]) => {
    if (!this.initialized) {
      this.setup(data);
    } else {
      this.updateBots(data);
    }
  };

  setup = (data: Robobot[]) => {
    this._setupCamera();
    this._setupRenderer();
    this._setupControls();
    this._setupLights();
    this._setupBackground();
    this._setupSceneObjects(data);
    this._setupSystems();
    this._setupListeners();
    this.initialized = true;
  };

  dispose = () => {
    this._removeListeners();
  };
  update = () => {};
  draw = () => {};

  updateBots = (data: Robobot[]) => {
    const s = this.controller.scene;

    this.disposableObjects.forEach(function (obj, i) {
      (obj.material as THREE.Material).dispose();
      obj.geometry.dispose();
      s.remove(obj);
    });
    this.disposableObjects = [];

    this._setupSceneObjects(data);
    this._resetCameraAndControls();
  };

  private initialCameraPosition!: THREE.Vector3;
  private initialControlsTarget!: THREE.Vector3;

  private _resetCameraAndControls = () => {
    this.controller.camera.position.copy(this.initialCameraPosition);
    this.controller.orbitControls.target.copy(this.initialControlsTarget);
    this.controller.orbitControls.update();
  };

  private _setupCamera = () => {
    this.controller.camera = new THREE.PerspectiveCamera(
      45,
      this.controller.dims.aspect,
      0.1,
      1500
    );
    this.controller.camera.position.set(0, 0, 10);
    this.initialCameraPosition = this.controller.camera.position.clone();
  };

  private _setupRenderer = () => {
    // main rendering pass
    this.renderPass = new RenderPass(
      this.controller.scene,
      this.controller.camera
    );
    this.controller.composer.addPass(this.renderPass);

    // outline pass
    this.outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      this.controller.scene,
      this.controller.camera
    );
    this.controller.composer.addPass(this.outlinePass);
  };

  private _setupControls = () => {
    this.controller.orbitControls = new OrbitControls(
      this.controller.camera,
      this.controller.overlay,
      window,
      this.controller._onControlsUpdate
    );
    this.controller.orbitControls.minZoom = 0.5;
    this.controller.orbitControls.maxZoom = 20;
    this.controller.orbitControls.zoomSpeed = 0.5;
    this.controller.orbitControls.update();
    this.initialControlsTarget = this.controller.orbitControls.target.clone();
  };

  private _setupLights = () => {
    const ambientLight = new THREE.AmbientLight(0x888888);
    ambientLight.name = "Ambient Light";
    this.controller.scene.add(ambientLight);
  };

  private _setupBackground = () => {};

  private _setupSceneObjects = (data: Robobot[]) => {
    const gridHelper = new THREE.GridHelper(100, 100);
    gridHelper.name = "Grid";
  
    const loader = new THREE.TextureLoader();
  
    // Replace the local file path with the hosted URL
    const textureUrl = "https://robobots-f79f8.web.app/misc/composite.png";
    loader.load(textureUrl, (texture) => {
      this._setupBots(texture, data);
    });
  };
  

  private _setupBots = (tex: THREE.Texture, data: Robobot[]) => {
    tex.magFilter = THREE.NearestFilter;

    const botPlaneSize = 1.0;
    const baseGeometry = new THREE.PlaneBufferGeometry(
      botPlaneSize,
      botPlaneSize
    );
    const baseMaterial = new THREE.MeshBasicMaterial({
      map: tex,
      transparent: true,
    });

    const numBots = data.length;
    const grid_sz = Math.ceil(Math.sqrt(numBots));

    const gridOffset = (grid_sz * botPlaneSize) / 2.0 - 0.5;

    for (var i = 0; i < numBots; i++) {
      const botData = data[i];
      const idx = parseInt(botData.name);
      const g = this._getBotGeometry(idx, baseGeometry);
      const mesh = new THREE.Mesh(g, baseMaterial);
      mesh.name = botData.name;

      // grid position
      const column = i % grid_sz;
      const row = Math.floor(i / grid_sz);
      const botX = column * botPlaneSize - gridOffset;
      const botY = (grid_sz - row - 1) * botPlaneSize - gridOffset;
      mesh.position.set(botX, botY, 0.0);
      this.controller.scene.add(mesh);
      this.disposableObjects.push(mesh);
    }
  };

  private _getBotGeometry = (
    idx: number,
    base: THREE.PlaneGeometry
  ): THREE.BufferGeometry => {
    const bufferGeometry = base.clone();

    const grid_sz = 100;
    const column = idx % grid_sz;
    const row = Math.floor(idx / 100);

    const uvAttribute = bufferGeometry.getAttribute("uv");

    const sz = 26.0 / IMG_WIDTH;
    const offsetX = column * sz;
    const offsetY = 1.0 - row * sz - sz;

    uvAttribute.setXY(0, offsetX, offsetY + sz);
    uvAttribute.setXY(1, offsetX + sz, offsetY + sz);
    uvAttribute.setXY(2, offsetX, offsetY);
    uvAttribute.setXY(3, offsetX + sz, offsetY);

    return bufferGeometry;
  };

  private _setupSystems = () => {};
  private _setupListeners = () => {
    this.controller.overlay.addEventListener(
      "mousemove",
      this._onDocumentMouseMove,
      false
    );
    this.controller.overlay.addEventListener(
      "mousedown",
      this._onDocumentMouseDown,
      false
    );
    this.listenersActive = true;
  };

  private _removeListeners = () => {
    this.controller.overlay.removeEventListener(
      "mousemove",
      this._onDocumentMouseMove,
      false
    );
    this.controller.overlay.removeEventListener(
      "mousedown",
      this._onDocumentMouseDown,
      false
    );
    this.listenersActive = false;
  };

  _onDocumentMouseMove = (event: MouseEvent) => {
    this._updateMouse(event);
    this.raycast();
  };

  _onDocumentMouseDown = (event: MouseEvent) => {
    this.lastTapTime = performance.now();
    this.controller.overlay.addEventListener(
      "mouseup",
      this._onDocumentMouseUp
    );
  };

  _onDocumentMouseUp = (event: MouseEvent) => {
    const now = performance.now();
    const isQuickClick = now - this.lastTapTime < this.quickClickTimeout;
    if (isQuickClick) {
      this._updateMouse(event);
      this.raycast();
      this.makeSelected(this.hovered);
    }
    this.controller.overlay.removeEventListener(
      "mouseup",
      this._onDocumentMouseUp
    );
  };

  private _updateMouse = (event: MouseEvent) => {
    const rect = this.controller.overlay.getBoundingClientRect();
    this.mouse.set(
      ((event.clientX - rect.left) / rect.width) * 2 - 1,
      -((event.clientY - rect.top) / rect.height) * 2 + 1
    );
  };

  raycast(): THREE.Intersection[] {
    let intersects: THREE.Intersection[] = [];

    if (
      this.mouse &&
      this.controller.camera &&
      this.selected.getValue() === undefined
    ) {
      this.raycaster.setFromCamera(this.mouse, this.controller.camera);

      intersects = this.raycaster.intersectObjects(this.disposableObjects);

      if (intersects.length > 0) {
        if (this.hovered !== intersects[0].object) {
          this.hovered = intersects[0].object;
          this.outlinePass.selectedObjects = [];
          this.outlinePass.selectedObjects.push(this.hovered);
          this.controller.requestDraw();
        }
      } else {
        if (this.hovered !== undefined) {
          this.hovered = undefined;
          this.outlinePass.selectedObjects = [];
        }
      }
    }
    return [];
  }

  makeSelected = (value?: THREE.Object3D | undefined) => {
    if (value !== this.selected.value) {
      if (value !== undefined) {
        this.selected.next(this.hovered);
      } else {
        this.selected.next(undefined);
      }
    }

    if (value !== undefined && this.listenersActive) {
      this._removeListeners();
    }

    if (value === undefined && this.listenersActive === false) {
      this._setupListeners();
    }
  };

  _drawHelper = (pos = new THREE.Vector3(), c = 0x00ffff, size = 0.25) => {
    const g = new THREE.SphereBufferGeometry(size, 12, 12);
    const m = new THREE.MeshBasicMaterial({ color: c });
    const v = new THREE.Mesh(g, m);
    v.position.set(pos.x, pos.y, pos.z);
    this.controller.scene.add(v);
  };

  static readonly factory: SceneFactory = {
    settings: EXPLORE_SCENE_SETTINGS,
    create(controller) {
      return new ExploreScene(controller);
    },
  };
}
