export enum Base {
  kitty = "kitty",
  betabot = "betabot",
  computer = "computer",
  droid = "droid",
  hero = "hero",
  hotbot = "hotbot",
  karel = "karel",
  protobot = "protobot",
  seeker = "seeker",
  killer = "killer",
}
