import { AttributeLocation } from "./Attributes";
import { Base } from "./Base";

type BaseAdjustment = { [key in AttributeLocation]: number[] | undefined };

export const Adjustments: { [key in Base]: BaseAdjustment } = {
  [Base.kitty]: {
    base: [0, 0],
    crown: undefined,
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: undefined,
    nose: undefined,
    right_cheek: [0, 0],
  },
  [Base.betabot]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: [0, 0],
    nose: [0, 0],
    right_cheek: [0, 0],
  },
  [Base.computer]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, -1],
    neck: [0, 0],
    nose: undefined,
    right_cheek: [1, 0],
  },
  [Base.droid]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: undefined,
    nose: [0, 0],
    right_cheek: [1, 0],
  },
  [Base.hero]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: [0, 0],
    nose: [0, 0],
    right_cheek: [0, 0],
  },
  [Base.hotbot]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: [0, 0],
    nose: [0, 0],
    right_cheek: [0, 0],
  },
  [Base.karel]: {
    base: [0, 0],
    crown: [0, 0],
    ears: undefined,
    eyes: [0, 0],
    mouth: [0, 0],
    neck: undefined,
    nose: [0, 0],
    right_cheek: [0, 0],
  },
  [Base.protobot]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: [0, 0],
    nose: [0, 0],
    right_cheek: [0, 0],
  },
  [Base.seeker]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: [0, 0],
    nose: [0, 0],
    right_cheek: [0, 0],
  },
  [Base.killer]: {
    base: [0, 0],
    crown: [0, 0],
    ears: [0, 0],
    eyes: [0, 0],
    mouth: [0, 0],
    neck: [0, 0],
    nose: [0, 0],
    right_cheek: [0, 0],
  },
};

export const BaseToSupportedLocations: Map<Base, AttributeLocation[]> = new Map(
  Object.entries(Adjustments).map(([base, adjustments]) => {
    const validLocations: AttributeLocation[] = Object.entries(adjustments)
      .filter(([, adjustment]) => adjustment !== undefined)
      .map(([location]) => location as AttributeLocation);

    return [base as Base, validLocations];
  })
);

// location-based adjustments to center an attribute icon within a canvas
export const IconLocationAdjustments: Record<string, { x: number; y: number }> = {
  base: { x: 0, y: 0 },
  crown: { x: 0, y: 9 },
  ears: { x: -9, y: -2 },
  eyes: { x: 0, y: 1 },
  mouth: { x: 0, y: -6 },
  neck: { x: 1, y: -10 },
  nose: { x: 0, y: -2 },
  right_cheek: { x: -9, y: -9 },
};

// attribute-specific adjustments to center an attribute icon within a canvas
export const SpecialIconAdjustments: Record<string, { x: number; y: number }> = {
  antenna: {x: 1, y: 9 },
  birthday_hat: {x: 1, y: 7},
  blue_wig: { x: 0, y: 7 },
  brunch_hat: { x: 1, y: 9 },
  bubblegum: { x: -1, y: -6 },
  caution_tape: {x: 0, y: -3},
  cigar: { x: 3, y: -6 },
  confetti: { x: -9, y: -1 },
  crank: { x: -9, y: 0},
  dish: { x: -8, y: 8 },
  flower: { x: -8, y: 0 },
  forcefield: { x: 0, y: 10 },
  green_toggle: {x: 0, y: -3},
  hoops: { x: -8, y: -3 },
  exhaust: { x: 3, y: 8 },
  karel: { x: 5, y: -4 },
  lever: { x: 0, y: 0 },
  magnet: { x: -8, y: -7 },
  patch: { x: -3, y: -3 },
  phone_mount: { x: -9, y: 0 },
  red_toggle: {x: 0, y: -3},
  rgb: { x: 0, y: 0},
  santa_hat: { x: 5, y: 9 },
  siphon: { x: -9, y: 0 },
  smoke: { x: -9, y: 0 },
  snapback: { x: 3, y: 7 },
  solar_panels: { x: -1, y: 9 },
  splitter: { x: -10, y: -1 },
  telescopes: { x: 2, y: 2 },
  thermal_vision: { x: 3, y: 1 },
  wreath: { x: 0, y: 6 },
  worried_brows: { x: 0, y: 3},
};