import React from "react";
import {
  Robobot,
  Attribute,
  Base,
  BaseToSupportedLocations,
  AttributesByLocationMap,
  LOCATIONS_IN_ONE_COLUMN,
  LOCATIONS_IN_TWO_COLUMNS,
  AttributeLocation,
  AttributeLocations,
} from "../core";
import { LoadedImageContext, RoboImageCanvas } from "../canvas";
import { useRobobots } from "../context";
import "./doodle.css";
import { PreviewBot, RobobotOverlay } from "../bot";
import { blankRobobotFilter, filterRobobot } from "../utils";
import { Link } from "react-router-dom";

const startingBot: Robobot = {
  name: "doodle_bot",
  base: Base.protobot,
};

const isSupportedLocation = (bot: Robobot, location: AttributeLocation) => {
  const supportedLocations = BaseToSupportedLocations.get(bot.base);
  return supportedLocations ? supportedLocations.includes(location) : false;
};

const updateCurrentLocationValue = (attribute: Attribute, bot: Robobot) => {
  const attributeName = attribute.name;
  if (attribute.location === "base") {
    bot.base = attributeName as Base;
    // Reset values for unsupported locations
    const supportedLocations = BaseToSupportedLocations.get(bot.base) || [];
    for (const location of AttributeLocations) {
      if (location !== "base" && !supportedLocations.includes(location as AttributeLocation)) {
        (bot as any)[location] = undefined;
      }
    }
  } else {
    // Only update if the attribute is supported at that location
    if (isSupportedLocation(bot, attribute.location)) {
      bot[attribute.location] =
        (bot[attribute.location] === attributeName) ? undefined : attributeName;
    }
  }
};

export const Doodle: React.FC = () => {
  const [playbot, setPlaybot] = React.useState<Robobot>(startingBot);
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const [matchingBots, setMatchingBots] = React.useState<Robobot[]>([]);
  const [selectedBot, setSelectedBot] = React.useState<Robobot | undefined>(undefined);
  const [isMobile, setIsMobile] = React.useState(false);
  const { robobots } = useRobobots();

  const onClear = () => setPlaybot(startingBot);

  const handleWindowResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  
  React.useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  React.useEffect(() => {
    if (robobots) {
      const doodleFilter = { ...blankRobobotFilter };
      AttributeLocations.forEach((location) => {
        if (playbot[location]) {
          doodleFilter[location] = playbot[location] as string;
        }
      });
      setMatchingBots(
        robobots.filter((bot) => filterRobobot(bot, doodleFilter)));
    }
  }, [playbot, robobots]);


  const exploreLink = React.useMemo(() => {
    const queryParams = new URLSearchParams();
    if (playbot.base) queryParams.set("bot_type", playbot.base);

    const attributeNames: string[] = [];
    AttributeLocations.forEach((location) => {
      if (location !== "base" && playbot[location]) {
        attributeNames.push(playbot[location] as string);
      }
    });
    if (attributeNames.length > 0) {
      queryParams.set("attributes", attributeNames.join(","));
    }
    return {
      pathname: "/explore",
      search: queryParams.toString(),
    };
  }, [playbot]);

  const getClearButtonClass = () => {
    // comparing with stringify should be good enough here
    return JSON.stringify(playbot) === JSON.stringify(startingBot)
      ? "clear-button-default"
      : "clear-button-active";
  };

  const onAttributeSelect = (attribute: Attribute) => {
    if (playbot) {
      const newPlaybot = { ...playbot };
      updateCurrentLocationValue(attribute, newPlaybot);
      setPlaybot(newPlaybot);
    }
  };

  const handlePreviewBotClick = React.useCallback((bot: Robobot) => {
    setSelectedBot(bot);
  }, []);

  const imagesLoaded = React.useContext(LoadedImageContext);
  const doodleContainerRef = React.useRef<HTMLDivElement>(null);
  const locationColumns = isMobile ? LOCATIONS_IN_ONE_COLUMN : LOCATIONS_IN_TWO_COLUMNS;
  const numPreviewBots = isMobile ? 2 : 5;

  return (
    <div ref={doodleContainerRef} className={`doodle-container relative w-full h-full ${selectedBot ? "overflow-hidden" : "overflow-auto"}`}>
      <button className="flex help-button absolute top-2 md:top-4 right-4 md:right-12 text-cyberorange text-12 md:text-16"
        onClick={() => setShowHelpModal(true)}>
        [?]
      </button>
      <div className="left-of-doodle" />
      <div className="doodle-view flex flex-col items-center">
        <div className="doodle-stage flex flex-row my-4 md:m-4">
          <div className="left-of-canvas w-6 md:w-8" />
          <div className="canvas-container flex">
            {imagesLoaded ? (
              <RoboImageCanvas robobot={playbot} background={false} pixelSize={8} />
            ) : (
              <img src="protobot_glitch.gif" alt="Loading..." width="192" height="192" />
            )}
          </div>

          <div className="right-of-canvas w-6 md:w-8" />
        </div>
        <div className="flex flex-row">
          <button
            onClick={onClear}
            className={`${getClearButtonClass()} text-12 px-2 rounded`}
            disabled={JSON.stringify(playbot) === JSON.stringify(startingBot)}
          >
            clear
          </button>
        </div>

        {
          matchingBots.length === 0
            ? <div className="mt-6" />
            : (
              <div className="flex flex-row justify-center items-center mt-6 bg-back2 px-2 md:px-6 py-2 rounded">
                <>
                {!isMobile && (
  <span className="text-10 md:text-12 text-light text-left">existing matches:</span>
)}                  {matchingBots.slice(0, numPreviewBots).map((bot) => (
                    <PreviewBot key={bot.name} bot={bot} onClick={() => handlePreviewBotClick(bot)} />
                  ))}
                </>
                {matchingBots.length > numPreviewBots && (
                  <Link
                    to={exploreLink}
                    className="text-10 md:text-12 text-cyberblue text-right underline"
                  >
                    ...and {matchingBots.length - numPreviewBots} more
                  </Link>
                )}
              </div>
            )
        }

        <div className="button-cluster-container grid w-full md:gap-4 justify-center rounded bg-back2 p-4 m-4">
          {locationColumns.map((column, columnIndex) => (
            <div key={columnIndex} className="button-cluster-column">
              {column.map((location, locationIndex) => {
                const attributes = AttributesByLocationMap.get(location)!;
                return (
                  <React.Fragment key={location}>
                    <ButtonCluster
                      attributes={attributes}
                      onClick={onAttributeSelect}
                      robobot={playbot}
                    />
                    {locationIndex < column.length - 1 && (
                      <div className="interpunct-separator text-20 text-center text-cybergreen">·</div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          ))}
        </div>
        <img className="w-24 h-24 mt-96 mb-4" src="robobot_paint.png" alt="robobots paint art" />
      </div>
      <div className="right-of-doodle" />
      <HelpModal
        show={showHelpModal}
        onClose={() => setShowHelpModal(false)}
      />
      <RobobotOverlay
        filteredBots={matchingBots}
        selectedBot={selectedBot}
        setSelectedBot={setSelectedBot}
        prevPageName="account"
        containerRef={doodleContainerRef}
      />
    </div>
  );
};

const ButtonCluster: React.FC<{
  attributes: Attribute[];
  onClick: (attribute: Attribute) => void;
  robobot: Robobot;
}> = ({ attributes, onClick, robobot }) => {

  const isActive = (attribute: Attribute): boolean => {
    return (robobot !== undefined) && (robobot[attribute.location] === attribute.name);
  };

  return (
    <div className="button-cluster">
      {attributes.map((attribute) => (
        <AttributeButton
          key={attribute.name}
          onClick={() => onClick(attribute)}
          active={isActive(attribute)}
          disabled={!isSupportedLocation(robobot, attribute.location)}
        >
          {attribute.name}
        </AttributeButton>
      ))}
    </div>
  );
};

type AttributeButtonProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
};

export const AttributeButton: React.FC<AttributeButtonProps> = ({
  children,
  onClick,
  active,
  disabled,
}) => {
  const buttonClass = disabled ?
    "button-disabled" :
    active ?
      "button-active" :
      "button-inactive";
  return (
    <button onClick={onClick} className={buttonClass} disabled={disabled}>
      <div className="button-content">{children}</div>
    </button>
  );
};

interface HelpModalProps {
  show: boolean;
  onClose: () => void;
}

export const HelpModal: React.FC<HelpModalProps> = ({ show, onClose }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="modal-overlay">
      <div className="modal flex flex-col align-start bg-back1 border-light">
        <div className="flex flex-row justify-center items-center mb-6">
          <img
            src="explore_icon_96.png"
            alt="Help"
            className="mr-2 h-8 w-8"
          />
          <span className="text-cyberblue font-bold text-18">what's going on?</span>
        </div>
        <div className="text-light text-14">
          <ul className="custom-bullet pl-4">
            <li className="mb-6">explore the complete set of robobot types and attributes!</li>
            <li className="mb-6">discover matching bots from the official collection!</li>
            <li>note: certain robobot types support a limited set of attributes!</li>
          </ul>
        </div>
        <div className="flex flex-row w-full justify-end">
          <button className="modal-close-button bg-back1 text-14 text-cyberorange" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );

};