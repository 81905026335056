import React from "react";
import Slider from "react-slider";
import { useLocation, useHistory } from "react-router-dom";
import { Robobot, Attributes, AttributeNameToLocationMap } from "../core";
import { EnableWebGLModal, ExploreContextProvider } from "./ExploreContext";
import { ThreeFullscreenView } from "./ThreeView";
import { useObservable } from "../utils";
import { ExploreContext } from "./ExploreContext";
import { ExploreScene } from "./RobobotsScene";
import { RobobotView } from "../bot";
import { LoadingScreen } from "../design-system";
import { useRobobots } from "../context";
import { RobobotFilter, filterRobobot, blankRobobotFilter } from "../utils";
import Web3 from "web3";

import "./explore.css";

const web3 = new Web3(Web3.givenProvider);

function useQuery() {
  const location = useLocation();
  return React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
}

function buildFilterFromQuery(query: URLSearchParams): RobobotFilter {
  const newFilter = { ...blankRobobotFilter };
  const botType = query.get("bot_type");
  if (botType) {
    newFilter.base = botType;
  }
  const attributesParam = query.get("attributes");
  if (attributesParam) {
    const attributes = attributesParam.split(",");
    attributes.forEach((attributeName) => {
      const location = AttributeNameToLocationMap.get(attributeName);
      if (location) {
        newFilter[location] = attributeName;
      }
    });
  }

  const minAttributes = query.get("min_attributes");
  if (minAttributes) newFilter.min_num_attributes = parseInt(minAttributes);

  const maxAttributes = query.get("max_attributes");
  if (maxAttributes) newFilter.max_num_attributes = parseInt(maxAttributes);

  const forSale = query.get("for_sale");
  if (forSale) {
    newFilter.for_sale = (forSale === "true");
    const minPrice = query.get("min_price");
    if (minPrice) newFilter.min_price = web3.utils.toWei(minPrice, "ether");
    const maxPrice = query.get("max_price");
    if (maxPrice) newFilter.max_price = web3.utils.toWei(maxPrice, "ether");
  }
  const botID = query.get("bot_id");
  if (botID) newFilter.id = botID;

  return newFilter;
}

export const Explore: React.FC = () => {
  const { robobots } = useRobobots();
  const [filteredRobobots, setFilteredRobobots] = React.useState<Robobot[]>();
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const isInitialLoad = React.useRef(true);
  const query = useQuery();

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const filterRobobots = React.useCallback(() => {
    if (!robobots) return;
    const hasSearchParams = query.toString() !== "";
    const defaultFilter = blankRobobotFilter;
    const newFilter = hasSearchParams
      ? buildFilterFromQuery(query)
      : defaultFilter;
    setFilteredRobobots(
      robobots.filter((bot) => filterRobobot(bot, newFilter))
    );
    isInitialLoad.current = false;
  }, [query, robobots]);

  React.useEffect(() => {
    filterRobobots();
  }, [robobots, filterRobobots]);

  return (
    <div className="rb-explore-container relative flex h-full w-full overflow-hidden">
      <ExploreContextProvider
        factory={ExploreScene.factory}
        data={filteredRobobots}
      >
        <button className={`panel-toggle ${isPanelOpen ? "panel-open" : ""}`} onClick={togglePanel}>
          {isPanelOpen ? (
            <img src="close_filter_icon.png" alt="close filtering" />
          ) : (
            <img src="filter_icon.png" alt="open filtering" />
          )}
        </button>
        <ExplorePanel
          filteredBotsCount={filteredRobobots?.length ?? undefined}
          filterRobobots={filterRobobots}
          isPanelOpen={isPanelOpen}
          setIsPanelOpen={setIsPanelOpen}
        />
        <div className="rb-explore flex flex-col w-full">
          {filteredRobobots ? <ThreeFullscreenView /> : <LoadingScreen />}
        </div>
        <ExploreOverlay filteredBots={filteredRobobots} />
        <EnableWebGLModal />
      </ExploreContextProvider>
    </div>
  );
};

// This overlay differs from generic RobobotOverlay, primarily because
// it uses the 'scene' for selection/deselection.
const ExploreOverlay: React.FC<{
  filteredBots: Robobot[] | undefined;
}> = ({ filteredBots }) => {
  const { controller } = React.useContext(ExploreContext);
  const scene = controller.robobotsScene as ExploreScene;
  const [selected] = useObservable(() => scene.selected, [scene.selected]);
  const [selectedBot, setSelectedBot] = React.useState<Robobot>();

  const close = (e: React.MouseEvent) => {
    scene.makeSelected(undefined);
    e.preventDefault();
    e.stopPropagation();
  };

  React.useEffect(() => {
    setSelectedBot(filteredBots?.find((bot) => bot.name === selected?.name));
  }, [filteredBots, selected]);

  return (
    <>
      {selected && (
        <RobobotView
          bot={selectedBot}
          setSelectedBot={setSelectedBot}
          filteredBots={filteredBots}
          close={close}
          prevPageName="explore"
        />
      )}
    </>
  );
};

type ExplorePanelProps = {
  filteredBotsCount?: number;
  filterRobobots: () => void;
  isPanelOpen: boolean;
  setIsPanelOpen?: (isOpen: boolean) => void;
};

export const ExplorePanel: React.FC<ExplorePanelProps> = ({
  filteredBotsCount,
  filterRobobots,
  isPanelOpen,
  setIsPanelOpen,
}) => {
  const query = useQuery();
  const history = useHistory();

  const initialBotType = query.get("bot_type") || "";
  const initialAttributesParam = query.get("attributes") || "";
  const initialMinAttributes = parseInt(query.get("min_attributes") || "0");
  const initialMaxAttributes = parseInt(query.get("max_attributes") || "7");
  const initialForSale = query.get("for_sale") === "true";
  const initialMinPrice = query.get("min_price") || "";
  const initialMaxPrice = query.get("max_price") || "";
  const initialBotID = query.get("bot_id") || "";

  // Check if the URL has any search params
  const hasSearchParams = query.toString() !== "";

  // Set initial state values
  const [botTypeInputValue, setBotTypeInputValue] =
    React.useState<string>(initialBotType);
  const [selectedAttributes, setSelectedAttributes] = React.useState<string[]>(
    hasSearchParams
      ? initialAttributesParam
        ? initialAttributesParam.split(",")
        : []
      : []
  );

  const [attributeRange, setAttributeRange] = React.useState({
    min: initialMinAttributes,
    max: initialMaxAttributes,
  });
  const [forSale, setForSale] = React.useState(initialForSale);
  const [minPrice, setMinPrice] = React.useState(initialMinPrice);
  const [maxPrice, setMaxPrice] = React.useState(initialMaxPrice);
  const [botID, setBotID] = React.useState<string>(initialBotID);

  const handleSearch = React.useCallback(() => {
    // Update the URL with the current search params
    const queryParams = new URLSearchParams();
    if (botTypeInputValue) queryParams.set("bot_type", botTypeInputValue);
    if (selectedAttributes.length > 0)
      queryParams.set("attributes", selectedAttributes.join(","));
    if (attributeRange.min !== 0)
      queryParams.set("min_attributes", attributeRange.min.toString());
    if (attributeRange.max !== 7)
      queryParams.set("max_attributes", attributeRange.max.toString());
    if (forSale) {
      queryParams.set("for_sale", "true");
      if (minPrice) queryParams.set("min_price", minPrice);
      if (maxPrice) queryParams.set("max_price", maxPrice);
    }
    if (botID) queryParams.set("bot_id", botID);

    const newQueryString = queryParams.toString();
    const currentQueryString = query.toString();

    if (newQueryString === currentQueryString) {
      // If the query string hasn't changed, just trigger re-filtering manually
      // (necessary for when initial load has no search params but uses random filter)
      filterRobobots();
    } else {
      // Otherwise, update the URL and let the useEffect hook handle re-filtering
      history.replace({
        pathname: "/explore",
        search: queryParams.toString(),
      });
    }
    if (setIsPanelOpen) {
      setIsPanelOpen(false);
    }
  }, [
    filterRobobots,
    attributeRange,
    botID,
    botTypeInputValue,
    selectedAttributes,
    forSale,
    minPrice,
    maxPrice,
    history,
    query,
    setIsPanelOpen,
  ]);

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        handleSearch();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    botTypeInputValue,
    selectedAttributes,
    attributeRange,
    forSale,
    minPrice,
    maxPrice,
    botID,
    handleSearch,
  ]);

  const handleRangeChange = (newValue: [number, number]) => {
    setAttributeRange({ min: newValue[0], max: newValue[1] });
  };

  return (
    <div className={`flex flex-col items-center rb-explore-ui ${isPanelOpen ? "panel-open" : ""} py-4`}>
      <div className="explore-searchbar-content">
        <BotTypeSearchBar
          inputValue={botTypeInputValue}
          setInputValue={setBotTypeInputValue}
        />
        <BotAttributeSearchBar
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
        />
        <RangeSlider
          initialValue={[attributeRange.min, attributeRange.max]}
          onChange={handleRangeChange}
        />
        <SquareCheckbox
          checked={forSale}
          onChange={(checked) => setForSale(checked)}
          label="For Sale"
        />
        {forSale && (
          <PriceFilter minPrice={minPrice} maxPrice={maxPrice} setMinPrice={setMinPrice} setMaxPrice={setMaxPrice} />
        )}
        <DigitSearch inputValue={botID} setInputValue={setBotID} />

        <button
          onClick={handleSearch}
          className="search-button mt-4 border-4 text-cybergreen font-bold focus:outline-none mx-auto"
        >
          SEARCH
        </button>
        {typeof filteredBotsCount !== "undefined" && (
          <div className="result-count mt-2 text-center text-sm">
            {filteredBotsCount === 0
              ? "(no results)"
              : filteredBotsCount === 1
                ? "(1 bot found)"
                : `(${filteredBotsCount} bots found)`}
          </div>
        )}
      </div>
    </div>
  );
};

interface BotTypeSearchBarProps {
  inputValue: string;
  setInputValue: (value: string) => void;
}

export const BotTypeSearchBar: React.FC<BotTypeSearchBarProps> = ({
  inputValue,
  setInputValue,
}) => {
  const [filteredValues, setFilteredValues] = React.useState<string[]>([]);
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const selections = React.useMemo(() => {
    return Attributes.filter((attribute) => attribute.location === "base")
      .map((attribute) => attribute.name)
      .sort((a, b) => a.localeCompare(b));
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => document.removeEventListener("mouseup", handleClickOutside);
  }, []);

  React.useEffect(() => {
    setFilteredValues(selections);
  }, [selections]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setShowDropdown(true);
    const searchTerm = e.target.value.toLowerCase();
    setFilteredValues(
      selections
        .filter((value) => value.toLowerCase().includes(searchTerm))
        .sort((a, b) => {
          const aStartsWith = a.toLowerCase().startsWith(searchTerm);
          const bStartsWith = b.toLowerCase().startsWith(searchTerm);
          if (aStartsWith && !bStartsWith) {
            return -1;
          } else if (!aStartsWith && bStartsWith) {
            return 1;
          } else {
            return a.localeCompare(b);
          }
        })
    );
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleSelect = (value: string) => {
    setInputValue(value);
    setFilteredValues(
      selections.filter((option) => option.toLowerCase().includes(value)));
    setShowDropdown(false);
  };

  const handleClear = () => {
    setInputValue("");
    setFilteredValues(selections);
    setShowDropdown(false);
  };

  return (
    <div
      className="w-full px-8 py-2 flex-col justify-center"
      ref={containerRef}
    >
      <label htmlFor="bot-type-search" className="mb-2 text-lg">
        Bot Type
      </label>
      <div className="relative w-full">
        <input
          id="bot-type-search"
          type="text"
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder="Type to search..."
          className="w-full px-3 py-2 pr-10 my-2 h-10 border border-gray-300 text-black focus:outline-none focus:border-blue-500"
          autoComplete="off"
        />
        {inputValue && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 h-14">
            <button
              onClick={handleClear}
              className="clear-search text-2xl text-back1 font-bold text-gray-500 focus:outline-none"
            >
              ×
            </button>
          </div>
        )}
        {showDropdown && (
          <ul className="dropdown-selection w-full mt-2 max-h-24 overflow-y-auto">
            {filteredValues.map((value, index) => (
              <li
                key={index}
                onClick={() => handleSelect(value)}
                className="dropdown-selection px-3 py-1.5 cursor-pointer"
              >
                {value}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

interface BotAttributeSearchBarProps {
  selectedAttributes: string[];
  setSelectedAttributes: (values: string[]) => void;
}

export const BotAttributeSearchBar: React.FC<BotAttributeSearchBarProps> = ({
  selectedAttributes,
  setSelectedAttributes,
}) => {
  const [inputValue, setInputValue] = React.useState<string>("");
  const [filteredValues, setFilteredValues] = React.useState<string[]>([]);
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const selectables = React.useMemo(() => {
    return Attributes.filter((attribute) => attribute.location !== "base")
      .map((attribute) => attribute.name)
      .sort((a, b) => a.localeCompare(b));
  }, []);

  React.useEffect(() => {
    setFilteredValues(selectables);
  }, [selectables]);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => document.removeEventListener("mouseup", handleClickOutside);
  }, []);

  const resolveMutuallyExclusiveAttributes = (
    oldSelectedAttributes: string[],
    newAttribute: string
  ): string[] => {
    const newAttributeLocation = AttributeNameToLocationMap.get(newAttribute);
    let foundMutuallyExclusive = false;
    const newSelectedAttributes = oldSelectedAttributes.map((oldAttribute) => {
      const oldAttributeLocation = AttributeNameToLocationMap.get(oldAttribute);
      if (newAttributeLocation === oldAttributeLocation) {
        foundMutuallyExclusive = true;
        return newAttribute; // Replace the old attribute with the new one
      }
      return oldAttribute; // Keep the old attribute
    });

    // If no previous selections occupy the same location, append the new selection
    if (!foundMutuallyExclusive) {
      newSelectedAttributes.push(newAttribute);
    }
    return newSelectedAttributes;
  };

  const calculateRelevance = (value: string, searchTerm: string): number => {
    const lowerCaseValue = value.toLowerCase();
    if (lowerCaseValue.startsWith(searchTerm)) {
      return 3;
    } else if (lowerCaseValue.includes(`_${searchTerm}`)) {
      return 2;
    } else if (lowerCaseValue.includes(searchTerm)) {
      return 1;
    }
    return 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setShowDropdown(true);
    const searchTerm = e.target.value.toLowerCase();
    setFilteredValues(
      selectables
        .filter((value) => value.toLowerCase().includes(searchTerm))
        .sort((a, b) => {
          const relevanceA = calculateRelevance(a, searchTerm);
          const relevanceB = calculateRelevance(b, searchTerm);
          if (relevanceA !== relevanceB) {
            return relevanceB - relevanceA;
          } else {
            return a.localeCompare(b);
          }
        })
    );
  };

  const handleFocus = () => {
    setShowDropdown(true);
  }

  const handleSelect = (value: string) => {
    // Check for mutually exclusive attributes and remove them if necessary
    const newSelectedAttributes = resolveMutuallyExclusiveAttributes(
      selectedAttributes,
      value
    );
    setSelectedAttributes(newSelectedAttributes);
    setInputValue("");
    setFilteredValues(selectables);
    setShowDropdown(false);
  };

  const handleRemove = (value: string) => {
    setSelectedAttributes(
      selectedAttributes.filter(
        (selectedAttributes) => selectedAttributes !== value
      )
    );
  };

  const handleClearAll = () => {
    setInputValue("");
    setSelectedAttributes([]);
    setFilteredValues(selectables);
    setShowDropdown(false);
  };

  return (
    <div
      className="w-full px-8 py-2 flex-col justify-center"
      ref={containerRef}
    >
      <div className="flex items-center">
        <label htmlFor="bot-attribute-search" className="mb-2 text-lg">
          Bot Attributes
        </label>
        {selectedAttributes.length > 0 && (
          <button
            onClick={handleClearAll}
            className="text-10 px-4 text-gray-500 focus:outline-none mb-2"
          >
            (clear all)
          </button>
        )}
      </div>

      <div className="relative w-full">
        <input
          id="bot-attribute-search"
          type="text"
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder="Type to search..."
          className="w-full px-3 py-2 pr-10 my-2 h-10 border border-gray-300 text-black focus:outline-none focus:border-blue-500"
          autoComplete="off"
        />
        {showDropdown && (
          <ul className="dropdown-selection w-full mt-2 max-h-28 overflow-y-auto">
            {filteredValues.map((value, index) => (
              <li
                key={index}
                onClick={() => handleSelect(value)}
                className="dropdown-selection px-3 py-1.5 cursor-pointer text-white"
              >
                {value}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="mt-4">
        {selectedAttributes.map((value, index) => (
          <div
            key={index}
            className="inline-flex items-center text-cyberblue bg-gray-200 px-2 py-1 mr-2 mb-2"
          >
            <span>{value}</span>
            <button
              onClick={() => handleRemove(value)}
              className="ml-2 text-cyberpink text-xl font-bold focus:outline-none"
            >
              ×
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

type ThumbProps = React.HTMLProps<HTMLDivElement>;

type ThumbState = {
  index: number;
};

export const RangeSlider = ({
  onChange,
  initialValue = [0, 7],
}: {
  onChange: (value: [number, number]) => void;
  initialValue?: [number, number];
}) => {
  const [value, setValue] = React.useState(initialValue);
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const selectedRef = React.useRef<HTMLDivElement>(null);

  // Effect for changing the track color between the thumbs
  React.useEffect(() => {
    if (sliderRef.current && selectedRef.current) {
      const selected = selectedRef.current;

      const minPercentage = (value[0] / 7) * 100;
      const maxPercentage = (value[1] / 7) * 100;

      selected.style.left = `${minPercentage}%`;
      selected.style.width = `${maxPercentage - minPercentage}%`;
    }
  }, [value]);

  // reset button logic
  const handleReset = () => {
    setValue([0, 7]);
    onChange([0, 7]);
  };
  const shouldDisplayReset = value[0] !== 0 || value[1] !== 7;

  const renderThumb = (props: ThumbProps, state: ThumbState) => {
    return (
      <div {...props} className="range-slider-thumb">
        <span className="range-slider-label">{value[state.index]}</span>
      </div>
    );
  };

  return (
    <div className="range-slider-container">
      <div className="flex items-center justify-start">
        <label htmlFor="attribute-range-slider" className="mb-2 text-lg">
          # Attributes
        </label>
        {shouldDisplayReset && (
          <button
            onClick={handleReset}
            className="text-10 px-4 text-gray-500 mb-2 focus:outline-none"
          >
            (reset)
          </button>
        )}
      </div>
      <div className="range-slider-row">
        <span className="range-slider-min-label">0</span>
        <div ref={sliderRef} className="range-slider-wrapper">
          <div ref={selectedRef} className="range-slider-selected" />
          <Slider
            aria-label="attribute-range-slider"
            className="range-slider"
            thumbClassName="range-slider-thumb"
            trackClassName="range-slider-track"
            min={0}
            max={7}
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
              onChange(newValue as [number, number]);
            }}
            renderThumb={renderThumb}
          />
        </div>
        <span className="range-slider-max-label">7</span>
      </div>
    </div>
  );
};

interface SquareCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const SquareCheckbox: React.FC<SquareCheckboxProps> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <div className="w-full px-8 py-2 flex items-center">
      <label className="mr-2">{label}</label>
      <div
        className={
          "checkbox w-5 h-5 cursor-pointer flex items-center justify-center transition-colors duration-200"
        }
        onClick={() => onChange(!checked)}
      >
        {checked && (
          <div className="w-3 h-3 bg-cybergreen transition-colors duration-200" />
        )}
      </div>
    </div>
  );
};

type PriceFilterProps = {
  minPrice: string;
  maxPrice: string;
  setMinPrice: React.Dispatch<React.SetStateAction<string>>;
  setMaxPrice: React.Dispatch<React.SetStateAction<string>>;
};

const PriceFilter: React.FC<PriceFilterProps> = ({ minPrice, maxPrice, setMinPrice, setMaxPrice }) => {
  const clearPriceFields = () => {
    setMinPrice('');
    setMaxPrice('');
  };

  return (
    <div className="w-full px-8 py-2 flex flex-col items-start">
      <div className="flex justify-start items-center w-full">
        <label className="mr-2">Sale Price (ETH)</label>
        {(minPrice || maxPrice) && <button onClick={clearPriceFields} className="text-12 font-bold text-cyberpink">x</button>}
      </div>
      <div className="flex flex-row py-2 space-x-2">
        <input
          id="bot-min-price"
          className="w-1/2 text-black text-12 no-arrows focus:outline-none"
          type="number"
          placeholder="Min"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
          autoComplete="off"
          step="any"
        />
        <input
          id="bot-max-price"
          className="w-1/2 text-black text-12 no-arrows focus:outline-none"
          type="number"
          placeholder="Max"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
          autoComplete="off"
          step="any"
        />
      </div>
    </div>
  );
};

interface DigitSearchProps {
  inputValue: string;
  setInputValue: (value: string) => void;
}

export const DigitSearch: React.FC<DigitSearchProps> = ({
  inputValue,
  setInputValue,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setInputValue(value);
    }
  };

  const handleClear = () => {
    setInputValue("");
  };

  return (
    <div className="flex w-full px-8 py-3 items-center align-center">
      <label htmlFor="bot-digit-search" className="text-lg mr-2">
        Bot ID
      </label>
      <div className="relative">
        <input
          id="bot-digit-search"
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="0000"
          className="digit-search-input w-24 px-3 py-2 pr-10 h-10 border border-gray-300 text-black focus:outline-none focus:border-blue-500"
          autoComplete="off"
        />
      </div>
      {inputValue.length > 0 && (
        <button
          onClick={handleClear}
          className="text-gray-500 text-10 px-3 focus:outline-none"
        >
          [x]
        </button>
      )}
    </div>
  );
};
