import React from "react";
import { useMemoOne } from "use-memo-one";
import { SpecialIconAdjustments } from "../core";

import { Attribute, Attributes } from "../core/Attributes";

class ImageManager {
  attributes: Attribute[];

  constructor() {
    this.attributes = [];
  }

  load = (refs: Attribute[]) => {
    this.attributes = refs;
  };
}

export const ImageContext = React.createContext<ImageManager>(new ImageManager());
export const LoadedImageContext = React.createContext<boolean>(false);

export const ImageContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // endpoint / object for accessing image data across the app
  const imageManager = useMemoOne(() => new ImageManager(), []);
  const [loaded, setLoaded] = React.useState(false);

  // offscreen canvas for drawing / extracting PNG data as raw Uint8Array
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const w = 24;
  const h = 24;

  React.useEffect(() => {
    const ctx = canvasRef.current?.getContext("2d", { willReadFrequently: true }) as CanvasRenderingContext2D | null;
    if (ctx && imageManager) {
      const loadAttributes = async () => {
        for (let i = 0; i < Attributes.length; i++) {
          const attribute = Attributes[i];
          const imageUrl = `https://robobots-f79f8.web.app/pieces/${attribute.name}.png`;
          // Fetch the image from the URL and convert it to a blob
          try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            // Create an image element with the blob URL
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            // Wait for the image to load before drawing it on the canvas
            img.onload = () => {
              ctx.drawImage(img, 0, 0);
              attribute.data = ctx.getImageData(0, 0, w, h).data;
              ctx.clearRect(0, 0, w, h);
            };
            // Set icon_adjustment for attributes with special adjustments
            if (SpecialIconAdjustments.hasOwnProperty(attribute.name)) {
              attribute.icon_adjustment = SpecialIconAdjustments[attribute.name];
            }
          } catch (error) {
            console.error(`Failed to load image from ${imageUrl}:`, error);
          }
        }
        imageManager.load(Attributes);
        setLoaded(true);
      };
      loadAttributes();
    }
  }, [imageManager]);

  return (
    <LoadedImageContext.Provider value={loaded}>
      <ImageContext.Provider value={imageManager}>
        {children}
        <div>
          <canvas ref={canvasRef} width={w} height={h} style={{ display: "none" }} />
        </div>
      </ImageContext.Provider>
    </LoadedImageContext.Provider>
  );
};
