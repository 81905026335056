import React from "react";
import { Robobot } from "../core";
import Web3 from "web3";
import { isNonZeroValue, formatEtherValue, compareWeiStrings, accountsEqual, CONTRACT_ADDRESS, formatWeiAsEther } from "../utils";
import { useWallet, useRobobots } from "../context";

const web3 = new Web3(Web3.givenProvider);
const contractAbi = require("../contract/robobots_abi.json");
const contract = new web3.eth.Contract(contractAbi, CONTRACT_ADDRESS);

declare global {
  interface Window {
    ethereum?: any;
  }
}

const CONTRACT_ERROR_CODES: { [key: string]: string } = {
  "018001": "Error: Not the NFT owner",
  "018002": "Error: Invalid transfer address",
  "003001": "Error: Invalid address",
  "003002": "Error: Not a valid NFT",
  "003003": "Error: Not the NFT owner or operator",
  "003004": "Error: Not the NFT owner, approved, or operator",
  "003005": "Error: Not able to receive NFT",
  "003006": "Sorry, this NFT has already been minted",
  "003007": "Error: Not the NFT owner",
  "003008": "Error: Unsupported action as NFT owner",
  "004001": "Error: NFT not for sale",
  "004002": "Error: NFT not for sale (to this user)",
  "004003": "Error: Insufficient bid or payment value",
  "004004": "Error: Owner cannot place bids",
  "004005": "Error: Bid does not exist",
  "004006": "Error: Not the highest bidder for this NFT",
  "004007": "Error: Internal Inconsistency"
};
// Special metamask code if user cancels transaction
const USER_REJECTED_REQUEST = 4001;

const getContractErrorMessage = (errorCode: string) => {
  return CONTRACT_ERROR_CODES[errorCode] || "Unknown error";
};

interface Transaction {
  transactionHash: string;
  blockNumber: number;
  from: string;
  to: string;
  gasUsed: number;
  cumulativeGasUsed: number;
  status: boolean;
}

const logTransaction = (transaction: Transaction) => {
  const transactionInfo =
    `Transaction Hash: ${transaction.transactionHash}
      Block Number: ${transaction.blockNumber}
      From: ${transaction.from}
      To: ${transaction.to}
      Gas Used: ${transaction.gasUsed}
      Cumulative Gas Used: ${transaction.cumulativeGasUsed}
      Status: ${transaction.status ? 'Success' : 'Failed'}`;
  console.log("Transaction:\n", transactionInfo);
}

const SITE_WILL_UPDATE_MESSAGE = "site will update upon tx confirmation";

type RobobotTransactionButtonsProps = {
  bot?: Robobot;
  close: (e: React.MouseEvent) => void; // for closing RobobotView
  prevPageName: string;
};

type TransactionFormState = {
  type: "address" | "ether" | "confirmation" | null;
  placeholder?: string;
  inputLabel?: string;
  onSubmit?: (value: string) => void;
  onCancel?: () => void;
  confirmationValue?: string;
  explainFee?: boolean;
};

export type TransactionStatus = {
  state: "success" | "failure" | "pending" | null;
  message: string;
  extraInfo: string;
  loading: boolean;
  hash: string;
};

export const DEFAULT_TRANSACTION_STATUS = {
  state: null,
  message: "",
  extraInfo: "",
  loading: false,
  hash: "",
}

export const RobobotTransactionButtons: React.FC<RobobotTransactionButtonsProps> = (
  { bot, close, prevPageName }) => {
  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
  const [transactionForm, setTransactionForm] = React.useState<TransactionFormState>({ type: null });
  const [transactionStatus, setTransactionStatus] = React.useState<TransactionStatus>(DEFAULT_TRANSACTION_STATUS);
  const loadingRef = React.useRef(transactionStatus.loading); // needed for async timeout stuff
  const loadingTimeouts = React.useRef<NodeJS.Timeout[]>([]);

  const { handleConnect, account } = useWallet();
  const { updateRobobots } = useRobobots();

  const resetTransactionStatus = () => {
    setTransactionStatus(DEFAULT_TRANSACTION_STATUS);
  };

  React.useEffect(() => {
    setTransactionForm({ type: null });
    setTransactionStatus(DEFAULT_TRANSACTION_STATUS);
    loadingRef.current = false;
    clearTransactionTimeouts();
    setButtonsDisabled(false); // re-enable buttons
  }, [account, bot]);

  // cancel the timeouts when the component is unmounted
  React.useEffect(() => {
    return () => {
      clearTransactionTimeouts();
    };
  }, []);

  // ------------------------------------------------------------------- //
  // ---------------------- Transaction Utilities ---------------------- //
  // ------------------------------------------------------------------- //

  const setLoading = (isLoading: boolean) => {
    loadingRef.current = isLoading;
    setTransactionStatus((prevState) => ({
      ...prevState,
      loading: isLoading,
    }));
  }

  const clearTransactionTimeouts = () => {
    loadingTimeouts.current.forEach((timeout) => {
      clearTimeout(timeout);
    });
  };

  const onTransactionHash = (hash: string) => {
    const etherscanLink = `https://etherscan.io/tx/${hash}`;
    console.log(`Etherscan link: ${etherscanLink}`);
    clearTransactionTimeouts();
    setLoading(false);

    setTransactionStatus((prevState) => ({
      ...prevState,
      state: "success",
      message: "success!",
      extraInfo: SITE_WILL_UPDATE_MESSAGE,
      hash: hash,
    }));
  }

  const setTransactionTimeouts = () => {
    // set extraInfo after 25, 75, and 150 seconds if loading is still true
    loadingTimeouts.current[0] = setTimeout(() => {
      if (loadingRef.current) {
        setTransactionStatus((prev) => ({
          ...prev,
          extraInfo: "this might take a minute...",
        }));
      }
    }, 25000);

    loadingTimeouts.current[1] = setTimeout(() => {
      if (loadingRef.current) {
        setTransactionStatus((prev) => ({
          ...prev,
          extraInfo: "still waiting for the transaction to be transmitted...",
        }));
      }
    }, 75000);

    loadingTimeouts.current[2] = setTimeout(() => {
      if (loadingRef.current) {
        setTransactionStatus((prev) => ({
          ...prev,
          extraInfo: "still no signal from the network. refresh the page and try again?",
        }));
      }
    }, 150000);
  }

  const handleTransactionError = (error: any) => {
    const regex = /\d{6}/;
    const match = regex.exec(error.message);
    const errorCode = match ? match[0] : "";

    setLoading(false);
    if (errorCode in CONTRACT_ERROR_CODES) {
      const errorMessage = getContractErrorMessage(errorCode);
      console.error(errorMessage);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: errorMessage,
        extraInfo: "",
      }));
    } else if (error.code === USER_REJECTED_REQUEST) {
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "transaction cancelled.",
        extraInfo: "",
      }));
      setButtonsDisabled(false);
    } else {
      console.error(error);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "an unknown error occurred. please come back later.",
        extraInfo: "",
      }));
    }
  };

  const prepareTransaction = () => {
    if (!bot || !bot.id) {
      console.error("Cannot identify the bot for the transaction.");
      setLoading(false);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "bot not found, please refresh the page and try again.",
        extraInfo: "",
      }));
      return false;
    }

    if (!account) {
      console.error("Cannot identify the current account.");
      setLoading(false);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "please 'connect' before attempting the transaction!",
        extraInfo: "",
      }));
      return false;
    }
    return true;
  };

  // onCancel function to hide the form and enable buttons
  const formOnCancel = () => {
    setTransactionForm({ type: null });
    setButtonsDisabled(false);
  };

  // ------------------------------------------------------------------- //
  // ------------------- Transaction Button Handlers ------------------- //
  // ------------------------------------------------------------------- //

  const handleTransferFormSubmit = async (toAddress: string) => {
    setLoading(true);
    setTransactionForm({ type: null }); // Remove the TransactionForm from the page
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    try {
      // Disable all buttons (typically already disabled)
      setButtonsDisabled(true);
      // Set up the transfer parameters
      const tokenId = bot?.id;
      const transferParams = {
        _from: account,
        _to: toAddress,
        _tokenId: tokenId,
        from: account,
      };
      const gasEstimate = await contract.methods
        .safeTransferFrom(account, toAddress, tokenId)
        .estimateGas(transferParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);

      // Call the safeTransferFrom function
      setTransactionTimeouts();
      const transferTransaction = await contract.methods
        .safeTransferFrom(account, toAddress, tokenId)
        .send({
          from: account,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(transferTransaction);
      await updateRobobots();
    } catch (error) {
      console.error(error);
      handleTransactionError(error);
    }
  };

  const handleTransferButton = async () => {
    resetTransactionStatus();
    setLoading(true);
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    setButtonsDisabled(true);
    setTransactionForm({
      type: "address",
      placeholder: "Enter recipient address",
      inputLabel: "Address:",
      onSubmit: handleTransferFormSubmit,
      onCancel: formOnCancel
    });
    setLoading(false);
  };

  const handleBuyButton = async () => {
    resetTransactionStatus();
    setLoading(true);
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    try {
      // Disable all buttons
      setButtonsDisabled(true);

      // Set up the buying parameters
      const tokenId = bot?.id;
      const buyingPriceInWei = bot?.sale_price;
      const buyParams = {
        from: account,
        value: buyingPriceInWei
      };
      const gasEstimate = await contract.methods.buy(tokenId).estimateGas(buyParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);

      // Call the buy function
      setTransactionTimeouts();
      const buyTransaction = await contract.methods
        .buy(tokenId)
        .send({
          from: account,
          value: buyingPriceInWei,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(buyTransaction);
      await updateRobobots();
    } catch (error) {
      console.error(error);
      handleTransactionError(error);
    }
  };

  const handleBidFormSubmit = async (bidValue: string) => {
    setLoading(true);
    setTransactionForm({ type: null }); // Remove the TransactionForm from the page
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    const currentBidWei = web3.utils.toWei(bidValue, "ether");
    if (compareWeiStrings(currentBidWei, bot?.highest_bid || "0") <= 0) {
      // currentBidWei is not than the existing bid
      console.error("Insufficient bid value.");
      setLoading(false);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "new bid must be greater than existing bid.",
        extraInfo: "",
      }));
      return;
    }

    try {
      // Disable all buttons (typically already disabled)
      setButtonsDisabled(true);
      // Set up the bidding parameters
      const tokenId = bot?.id;
      const bidValueInWei = web3.utils.toWei(bidValue, "ether");
      const bidParams = {
        from: account,
        value: bidValueInWei
      };
      const gasEstimate = await contract.methods.makeBid(tokenId).estimateGas(bidParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);

      // Call the makeBid function
      setTransactionTimeouts();
      const bidTransaction = await contract.methods
        .makeBid(tokenId)
        .send({
          from: account,
          value: bidValueInWei,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(bidTransaction);
      await updateRobobots();
    } catch (error) {
      console.error(error);
      handleTransactionError(error);
    }
  };

  const handleBidButton = async () => {
    resetTransactionStatus();
    setLoading(true);
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    setButtonsDisabled(true);
    const hasHighestBid = isNonZeroValue(bot?.highest_bid);
    const highestBidEth = hasHighestBid
      ? formatEtherValue(parseFloat(web3.utils.fromWei(bot?.highest_bid || '0', "ether")))
      : null;
    const bidPlaceholder = hasHighestBid
      ? `Enter bid (>${highestBidEth} ETH)`
      : "Enter bid";
    setTransactionForm({
      type: "ether",
      placeholder: bidPlaceholder,
      inputLabel: "Bid:",
      onSubmit: handleBidFormSubmit,
      onCancel: formOnCancel
    });
    setLoading(false);
  };

  const handleSellFormSubmit = async (minSalePrice: string) => {
    setLoading(true);
    setTransactionForm({ type: null }); // Remove the TransactionForm from the page
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    try {
      // Disable all buttons (typically already disabled)
      setButtonsDisabled(true);
      // Set up the offer parameters
      const tokenId = bot?.id;
      const minSalePriceInWei = web3.utils.toWei(minSalePrice, "ether");
      const offerParams = {
        _tokenId: tokenId,
        _minSalePriceInWei: minSalePriceInWei,
        from: account,
      };
      const gasEstimate = await contract.methods
        .makeOffer(tokenId, minSalePriceInWei)
        .estimateGas(offerParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);

      // Call the makeOffer function
      setTransactionTimeouts();
      const offerTransaction = await contract.methods
        .makeOffer(tokenId, minSalePriceInWei)
        .send({
          from: account,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(offerTransaction);
      await updateRobobots();
    } catch (error) {
      console.error(error);
      handleTransactionError(error);
    }
  };

  const handleSellButton = async () => {
    resetTransactionStatus();
    setLoading(true);
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    setButtonsDisabled(true);
    setTransactionForm({
      type: "ether",
      placeholder: "enter sale price",
      inputLabel: "Price:",
      onSubmit: handleSellFormSubmit,
      onCancel: formOnCancel,
      explainFee: true,
    });
    setLoading(false);
  };

  const handleAcceptBidButton = async () => {
    resetTransactionStatus();
    setLoading(true);
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    const highestBidWei = bot?.highest_bid;
    const highestBidEth = highestBidWei
      ? formatEtherValue(parseFloat(web3.utils.fromWei(highestBidWei || '0', "ether")))
      : null;
    if (highestBidEth === null) {
      console.error("Bid not found.");
      setLoading(false);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "bid not found, please refresh the page and try again.",
        extraInfo: "",
      }));
      return;
    }
    setButtonsDisabled(true);

    const confirmationText = `Are you sure you want to accept the bid of ${highestBidEth} ETH?`;
    setTransactionForm({
      type: "confirmation",
      placeholder: confirmationText,
      onSubmit: handleAcceptBidFormSubmit,
      onCancel: formOnCancel,
      confirmationValue: highestBidWei?.toString(),
      explainFee: true,
    });
    setLoading(false);
  };

  const handleAcceptBidFormSubmit = async (minSalePriceInWei: string) => {
    setLoading(true);
    // Remove the TransactionForm
    setTransactionForm({ type: null });
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    try {
      // Disable all buttons
      setButtonsDisabled(true);
      const tokenId = bot?.id;
      const acceptBidParams = {
        _tokenId: tokenId,
        _minSalePriceInWei: minSalePriceInWei,
        from: account,
      };
      const gasEstimate = await contract.methods
        .acceptBid(tokenId, minSalePriceInWei)
        .estimateGas(acceptBidParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);

      // Call accept bid function
      setTransactionTimeouts();
      const acceptBidTransaction = await contract.methods
        .acceptBid(tokenId, minSalePriceInWei)
        .send({
          from: account,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(acceptBidTransaction);
      await updateRobobots();
    } catch (error) {
      console.error(error);
      handleTransactionError(error);
    }
  };

  const handleRemoveOfferButton = async () => {
    resetTransactionStatus();
    setLoading(true);
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    try {
      // Disable all buttons
      setButtonsDisabled(true);
      const tokenId = bot?.id;
      const removeOfferParams = {
        _tokenId: tokenId,
        from: account,
      };
      const gasEstimate = await contract.methods
        .withdrawOffer(tokenId)
        .estimateGas(removeOfferParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);

      // Call withdraw offer function
      setTransactionTimeouts();
      const withdrawOfferTransaction = await contract.methods
        .withdrawOffer(tokenId)
        .send({
          from: account,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(withdrawOfferTransaction);
      await updateRobobots();
    } catch (error) {
      console.error(error);
      handleTransactionError(error);
    }
  };

  const handleRemoveBidButton = async () => {
    resetTransactionStatus();
    setLoading(true);
    if (!prepareTransaction()) {
      setLoading(false);
      return;
    }
    try {
      // Disable all buttons
      setButtonsDisabled(true);
      const tokenId = bot?.id;
      const removeBidParams = {
        _tokenId: tokenId,
        from: account,
      };
      const gasEstimate = await contract.methods
        .withdrawBid(tokenId)
        .estimateGas(removeBidParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);

      // Call withdraw bid function
      setTransactionTimeouts();
      const withdrawBidTransaction = await contract.methods
        .withdrawBid(tokenId)
        .send({
          from: account,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(withdrawBidTransaction);
      await updateRobobots();
    } catch (error) {
      console.error(error);
      handleTransactionError(error);
    }
  };

  const navToOpensea = () => {
    if (bot?.name === undefined)
      return;
    const botNumber = parseInt(bot?.name, 10); // convert bot to a number
    const botString = botNumber.toString(); // convert bot back to a string without leading zeros
    const url = `https://opensea.io/assets/ethereum/${CONTRACT_ADDRESS}/${botString}`;
    window.open(url, '_blank', 'noopener,noreferrer'); // open the link in a new tab
  }

  // ------------------------------------------------------------------- //
  // ----------------------- Button HTML Helpers ----------------------- //
  // ------------------------------------------------------------------- //

  const transactionButtonHtml = (
    key: string,
    onClick: React.MouseEventHandler<HTMLButtonElement>,
    buttonText: string
  ) => {
    const transactionButtonClass = buttonsDisabled
      ? "transaction-button-disabled"
      : "transaction-button";
    return (
      <button
        key={key}
        className={`flex ${transactionButtonClass} w-auto h-100 my-1 md:my-0 md:mx-3`}
        onClick={onClick}
        disabled={buttonsDisabled}
      >
        {buttonText}
      </button>
    );
  };

  const buyButton = () => {
    if (!isNonZeroValue(bot?.sale_price)) return; // this has already been checked
    const salePriceEth = web3.utils.fromWei(bot?.sale_price || '0', "ether");
    const formattedSalePriceEth = formatEtherValue(parseFloat(salePriceEth));
    return transactionButtonHtml(
      "buy",
      handleBuyButton,
      `Buy for ${formattedSalePriceEth} ETH`
    );
  }

  const bidButton = () => {
    let buttonText;
    if (isNonZeroValue(bot?.highest_bid)) {
      const bidValueEth = web3.utils.fromWei(bot?.highest_bid || '0', "ether");
      const formattedBidValueEth = formatEtherValue(parseFloat(bidValueEth));
      buttonText = `Bid >${formattedBidValueEth} ETH`;
    } else {
      buttonText = "Place Bid";
    }

    return transactionButtonHtml(
      "bid",
      handleBidButton,
      buttonText
    );
  }

  const sellBotButton = () => transactionButtonHtml(
    "sellBot",
    handleSellButton,
    "Sell Bot"
  );

  const transferButton = () => transactionButtonHtml(
    "transfer",
    handleTransferButton,
    "Transfer"
  );

  const acceptBidButton = () => {
    if (!isNonZeroValue(bot?.highest_bid)) return; // this has already been checked
    const bidValueEth = web3.utils.fromWei(bot?.highest_bid || '0', "ether");
    const formattedBidValueEth = formatEtherValue(parseFloat(bidValueEth));
    return transactionButtonHtml(
      "acceptBid",
      handleAcceptBidButton,
      `Accept ${formattedBidValueEth} ETH Bid`
    );
  };

  const removeOfferButton = () => {
    if (!isNonZeroValue(bot?.sale_price)) return; // this has already been checked
    const salePriceEth = web3.utils.fromWei(bot?.sale_price || '0', "ether");
    const formattedSalePriceEth = formatEtherValue(parseFloat(salePriceEth));
    return transactionButtonHtml(
      "removeOffer",
      handleRemoveOfferButton,
      `Remove ${formattedSalePriceEth} ETH Offer`
    );
  }

  const removeBidButton = () => transactionButtonHtml(
    "removeBid",
    handleRemoveBidButton,
    `Remove Bid`
  );

  const connectWalletButton = () => transactionButtonHtml(
    "connectWallet",
    handleConnect,
    "Connect Wallet"
  );

  const openseaButton = () => (
    <div>
      <button
        key="opensea"
        className="flex transaction-button w-auto h-100 mx-auto"
        onClick={navToOpensea}
      >
        Transact on OpenSea
      </button>
      <div className="flex flex-row justify-center items-center text-10 my-1">
        {isNonZeroValue(bot?.opensea_price) && (
          <div className="text-cybergreen mx-1">
            <span>ASK:&nbsp;{formatWeiAsEther(bot?.opensea_price)}</span>
          </div>
        )}
        {isNonZeroValue(bot?.opensea_bid) && (
          <div className="text-cyberyellow mx-1">
            <span>BID:&nbsp;{formatWeiAsEther(bot?.opensea_bid)}</span>
          </div>
        )}
      </div>
    </div>
  );

  const transactionMessageHtml = () => {
    if (loadingRef.current) {
      return (
        <div className="flex flex-col justify-center items-center mt-4">
          <img src={`${bot?.base}_swirl.gif`} alt="loading" className="h-14 w-14 mx-2" />
          {transactionStatus.extraInfo ? (
            <div className="text-light text-12 mt-2">( {transactionStatus.extraInfo} )</div>
          ) : null}
        </div>
      );
    } else if (transactionStatus.message) {
      const etherscanUrl = transactionStatus.hash ? `https://etherscan.io/tx/${transactionStatus.hash}` : null;
      return (
        <div className="flex flex-col justify-center items-center mt-4">
          <div className="flex flex-col p-2 bg-back2 items-center cursor-pointer">
            <div className={`font-bold text-center ${transactionStatus.state === "failure" ? "text-cyberpink" : "text-cyberblue"}`}>
              {transactionStatus.message}
              {etherscanUrl ? (
                <span>
                  {" "}view pending transaction on{" "}
                  <a href={etherscanUrl} target="_blank" rel="noopener noreferrer" className="text-cybergreen underline">
                    etherscan
                  </a>
                </span>
              ) : null}
            </div>
            <div className="text-cybergreen text-12 p-1" onClick={close}>&larr; back to '{prevPageName}'</div>
          </div>
          {transactionStatus.extraInfo ? (
            <div className="text-light text-12 mt-2">( {transactionStatus.extraInfo} )</div>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  const getButtonsForBot = () => {
    const buttons = [];
    if (!account) {
      // without an account connected, only push a connect button
      buttons.push(connectWalletButton());
    } else {
      if (accountsEqual(account, bot?.owner)) {
        // The user owns this bot
        buttons.push(transferButton());
        buttons.push(sellBotButton());
        if (isNonZeroValue(bot?.highest_bid)) {
          buttons.push(acceptBidButton());
        }
        if (isNonZeroValue(bot?.sale_price)) {
          buttons.push(removeOfferButton());
        }
      } else {
        // The user does not own this bot
        buttons.push(bidButton());
        if (accountsEqual(bot?.bidder, account)) {
          buttons.push(removeBidButton());
        }
        if (isNonZeroValue(bot?.sale_price) &&
          (!bot?.only_sell_to ||
            bot?.only_sell_to === "0x0000000000000000000000000000000000000000" ||
            accountsEqual(bot?.only_sell_to, account))) {
          buttons.push(buyButton());
        }
      }
    }
    return buttons;
  };

  // ------------------------------------------------------------------- //
  // ---------------- TransactionButtons Returned HTML ----------------- //
  // ------------------------------------------------------------------- //


  const siteTransactionButtons = getButtonsForBot();
  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="flex flex-col md:flex-row mt-6 justify-center items-center w-full">
        {/* <div className="text-center text-cyberpink border-cyberpink border-2 text-14 p-2 rounded">
          (robobots.art marketplace under repair, fix incoming!)
        </div> */}
        {siteTransactionButtons}
      </div>
      <form noValidate={true}>
        <TransactionForm
          type={transactionForm.type}
          placeholder={transactionForm.placeholder || ""}
          inputLabel={transactionForm.inputLabel || ""}
          onSubmit={transactionForm.onSubmit || (() => { })}
          onCancel={transactionForm.onCancel || (() => { })}
          confirmationValue={transactionForm.confirmationValue || ""}
          explainFee={transactionForm.explainFee || false}
        />
      </form>
      <div className="flex justify-center items-center w-full my-2 md:my-4">
        <hr className="border-t border-light w-1/4" />
        <span className="text-light px-2">or</span>
        <hr className="border-t border-light w-1/4" />
      </div>
      {openseaButton()}
      {transactionMessageHtml()}
    </div>
  );
};

// ------------------------------------------------------------------- //
// ------------------------ TransactionForm -------------------------- //
// ------------------------------------------------------------------- //

interface TransactionFormProps {
  type: "address" | "ether" | "confirmation" | null;
  onSubmit: (value: string) => void;
  onCancel: () => void;
  placeholder: string;
  inputLabel: string;
  confirmationValue?: string;
  explainFee?: boolean;
}

export const TransactionForm: React.FC<TransactionFormProps> = ({
  type,
  onSubmit,
  onCancel,
  placeholder,
  inputLabel,
  confirmationValue,
  explainFee,
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (type === "confirmation" && confirmationValue) {
      onSubmit(confirmationValue);
    } else {
      onSubmit(inputValue);
    }
    setInputValue(""); // reset input value
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onCancel();
    setInputValue(""); // reset input value
  };

  if (!type) {
    return null;
  }

  const inputType = type === "address" ? "text" : "number";

  const renderInput = () => {
    if (type === "confirmation") {
      return null;
    }

    const inputWidth = type === "ether" ? "w-60 md:w-72" : "w-60 md:w-96";
    const fontSize = type === "ether" ? "text-12 md:text-14" : "text-10 md:text-14";
    const labelPadding = type === "ether" ? "pr-10" : "pr-2";
    const autoComplete = type === "address" ? "on" : "off";

    return (
      <div className="relative flex items-center">
        {inputLabel && (
          <span className="text-14 md:text-16 text-light mr-2">{inputLabel}</span>
        )}
        <input
          type={inputType}
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className={`${inputWidth} max-w-full py-1 pl-2 ${labelPadding} ${fontSize} border border-gray-300 text-black no-arrows`}
          spellCheck={false}
          style={type === "ether" ? { paddingRight: "40px" } : {}}
          autoComplete={autoComplete}
          step="any"
        />
        {type === "ether" && (
          <span className={`absolute right-2 top-1/2 transform -translate-y-1/2 ${fontSize} text-black`}>
            ETH
          </span>
        )}
      </div>
    );
  };

  const renderLabel = () => {
    if (type !== "confirmation") {
      return null;
    }

    return <p className="text-14 text-center text-cybergreen mb-2">{placeholder}</p>;
  };

  return (
    <div className="flex flex-col md:flex-row w-auto mt-4 border-2 border-cybergreen p-1 md:p-2 justify-center items-center">
      <div className="flex flex-col w-auto items-center p-1 md:p-2">
        {renderLabel()}
        {renderInput()}
        {explainFee && (
          <span className="text-10 text-light mt-2">
            ( a 1% fee applies to all robobot sales )
          </span>
        )}
      </div>
      <div className="flex flex-row md:flex-col justify-center p-2">
        <button className="submit-button mr-2 md:mr-0 md:mb-2" onClick={handleSubmit}>
          Submit
        </button>
        <button className="cancel-button" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

// Set default value of explainFee to false
TransactionForm.defaultProps = {
  explainFee: false,
};

type RedeemButtonProps = {
  pendingWithdrawalsEthString: string;
};

export const RedeemButton: React.FC<RedeemButtonProps> = ({ pendingWithdrawalsEthString }) => {
  const { account } = useWallet();
  const [transactionStatus, setTransactionStatus] = React.useState<TransactionStatus>(DEFAULT_TRANSACTION_STATUS);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const loadingRef = React.useRef(transactionStatus.loading); // needed for async timeout stuff
  const loadingTimeouts = React.useRef<NodeJS.Timeout[]>([]);

  const setLoading = (isLoading: boolean) => {
    loadingRef.current = isLoading;
    setTransactionStatus((prevState) => ({
      ...prevState,
      loading: isLoading,
    }));
  }

  const clearTransactionTimeouts = () => {
    loadingTimeouts.current.forEach((timeout) => {
      clearTimeout(timeout);
    });
  };

  React.useEffect(() => {
    return () => {
      clearTransactionTimeouts();
    };
  }, []);

  const onTransactionHash = (hash: string) => {
    const etherscanLink = `https://etherscan.io/tx/${hash}`;
    console.log(`Etherscan link: ${etherscanLink}`);
    clearTransactionTimeouts();
    setLoading(false);

    setTransactionStatus((prevState) => ({
      ...prevState,
      state: "success",
      message: "success!",
      extraInfo: SITE_WILL_UPDATE_MESSAGE,
      hash: hash,
    }));
  }

  const setTransactionTimeouts = () => {
    // set extraInfo after 20/60/100 seconds if loading is still true
    loadingTimeouts.current[0] = setTimeout(() => {
      if (loadingRef.current) {
        setTransactionStatus((prev) => ({
          ...prev,
          state: "pending",
          message: "just a sec...",
        }));
      }
    }, 20000);
    loadingTimeouts.current[1] = setTimeout(() => {
      if (loadingRef.current) {
        setTransactionStatus((prev) => ({
          ...prev,
          state: "pending",
          message: "still waiting for the transaction to be transmitted...",
        }));
      }
    }, 60000);
    loadingTimeouts.current[2] = setTimeout(() => {
      if (loadingRef.current) {
        setTransactionStatus((prev) => ({
          ...prev,
          state: "pending",
          message: "still no signal from the network. refresh and try again?",
        }));
      }
    }, 100000);
  }

  const handleRedeemError = (error: any) => {
    setLoading(false);
    if (error.code === USER_REJECTED_REQUEST) {
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "(withdraw cancelled)",
      }));
      setButtonDisabled(false);
    } else {
      console.error(error);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "(unknown error, please refresh and try again)",
      }));
    }
  };

  const handleRedeem = async () => {
    setLoading(true);
    if (!account) {
      setLoading(false);
      setTransactionStatus((prevState) => ({
        ...prevState,
        state: "failure",
        message: "account not found, please refresh and try again.",
      }));
      return;
    }
    try {
      // Disable the redeem button
      setButtonDisabled(true);
      // Set up the withdrawFunds parameters
      const withdrawParams = { from: account };
      const gasEstimate = await contract.methods.withdrawFunds().estimateGas(withdrawParams);
      const paddedGasEstimate = Math.ceil(gasEstimate * 1.1);
      // Call the contract withdrawFunds function
      setTransactionTimeouts();
      const tx = await contract.methods
        .withdrawFunds()
        .send({
          from: account,
          gas: paddedGasEstimate,
        }).on("transactionHash", (hash: string) => onTransactionHash(hash));
      logTransaction(tx);
    } catch (error) {
      handleRedeemError(error);
    }
  };

  const messageHtml = () => {
    if (transactionStatus.message) {
      const etherscanUrl = transactionStatus.hash ? `https://etherscan.io/tx/${transactionStatus.hash}` : null;
      return (
        <div className={`flex flex-col md:flex-row justify-start items-start md:ml-2 ${transactionStatus.state === "failure" ? "text-cyberpink" :
          transactionStatus.state === "success" ? "text-cybergreen" : "text-light"}`}>
          {transactionStatus.message}
          {etherscanUrl ? (
            <span className="md:ml-2">
              track on {" "}
              <a href={etherscanUrl} target="_blank" rel="noopener noreferrer" className="text-cybergreen underline">
                etherscan
              </a>
            </span>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="flex flex-col md:flex-row text-14 items-start md:items-center my-1">
      <div className="flex flex-row items-center my-1">
        <div className="md:mr-2">proceeds:{" "}</div>
        <div className="text-cybergreen">{pendingWithdrawalsEthString}</div>
      </div>
      <button
        className={`my-1 md:my-0 md:ml-2 rounded-lg px-3 py-0.25 justify-center ${buttonDisabled ? "redeem-button-disabled" : "redeem-button"} relative`}
        onClick={handleRedeem}
        disabled={buttonDisabled}
      >
        REDEEM
      </button>
      <div className="flex my-1 mb-1 md:mb-0 items-center md:pl-2">
        {loadingRef.current ? (
          <img src="protobot_swirl.gif" alt="Loading" className="h-4 w-4" />
        ) : null}
        {messageHtml()}
      </div>
    </div>
  );

};
