import { useWindowSize } from "react-use";
import { ThemeBreakpoints } from "./Theme";

const tablet = 768;
const desktop = 1024;

export const breakpoints: ThemeBreakpoints = {
  mobile: "0px",
  tablet: `${tablet}px`,
  desktop: `${desktop}px`,
};

function getBreakpoint(width: number): "mobile" | "tablet" | "desktop" {
  if (width < tablet) {
    return "mobile";
  } else if (width < desktop) {
    return "tablet";
  } else {
    return "desktop";
  }
}

export function useBreakpoint() {
  const { width } = useWindowSize();
  return getBreakpoint(width);
}
