import React from "react";
import { Robobot } from "../core";

export interface RobobotsContextType {
  robobots: Robobot[] | undefined;
  setRobobots: (robobots: Robobot[] | undefined) => void;
  updateRobobots: () => void;
}

export const RobobotsContext = React.createContext<RobobotsContextType>({
  robobots: undefined,
  setRobobots: () => { },
  updateRobobots: () => { },
});

export const RobobotsContextProvider: React.FC = ({ children }) => {
  const [robobots, setRobobots] = React.useState<Robobot[]>();

  const fetchRobobots = React.useCallback(() => {
    Promise.all([
      fetch("https://us-central1-robobots-f79f8.cloudfunctions.net/api/robobots"),
      fetch("https://us-central1-robobots-f79f8.cloudfunctions.net/api/externalMarkets")
    ])
      .then(async ([response1, response2]) => {
        if (!response1.ok || !response2.ok) {
          throw new Error(`Error fetching data: ${response1.statusText}, ${response2.statusText}`);
        }

        // Parse the responses as JSON
        const robobotsRes = await response1.json();
        const externalMarketsRes = await response2.json();

        // Merge the arrays by their index
        const merged = robobotsRes.map((item: Robobot, index: number) => {
          return Object.assign({}, item, externalMarketsRes[index]);
        });

        setRobobots(merged);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Fetch robobots when the component mounts
  React.useEffect(() => {
    fetchRobobots();
  }, [fetchRobobots]);

  const updateDB = React.useCallback(async (): Promise<boolean> => {
    try {
      const response = await fetch("https://us-central1-robobots-f79f8.cloudfunctions.net/api/updateRecord", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({})
      });

      if (!response.ok) {
        console.error(`Failed to update DB: ${response.status} ${response.statusText}`);
        return false;
      }
      return true;
    } catch (error) {
      console.error(`Failed to update DB: ${error}`);
      return false;
    }
  }, []);

  const updateRobobots = React.useCallback(async () => {
    const updateSuccessful = await updateDB();
    if (updateSuccessful) {
      setTimeout(() => {
        fetchRobobots();
      }, 3000);
    }
  }, [fetchRobobots, updateDB]);

  return (
    <RobobotsContext.Provider value={{ robobots, setRobobots, updateRobobots }}>
      {children}
    </RobobotsContext.Provider>
  );
};

export const useRobobots = () => {
  return React.useContext(RobobotsContext);
};
