import React from "react";
import { ContractEvent } from "../core";
import { accountsEqual } from "../utils";

export interface ContractEventsContextType {
  events: ContractEvent[] | undefined;
  setEvents: (events: ContractEvent[] | undefined) => void;
  fetchEvents: () => void;
  getEventsByTokenId: (tokenId: string) => ContractEvent[] | undefined;
  getEventsByAccount: (address: string) => ContractEvent[] | undefined;
}

export const ContractEventsContext = React.createContext<ContractEventsContextType>({
  events: undefined,
  setEvents: () => { },
  fetchEvents: () => { },
  getEventsByTokenId: () => undefined,
  getEventsByAccount: () => undefined,
});

export const ContractEventsContextProvider: React.FC = ({ children }) => {
  const [events, setEvents] = React.useState<ContractEvent[]>();

  const fetchEvents = React.useCallback(() => {
    fetch("https://us-central1-robobots-f79f8.cloudfunctions.net/api/events")
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        setEvents(Object.values(json) as ContractEvent[]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getEventsByTokenId = React.useCallback(
    (tokenId: string): ContractEvent[] | undefined => {
      if (!events) {
        return undefined;
      }
      return events.filter((event) => event.id === tokenId);
    },
    [events]
  );

  const getEventsByAccount = React.useCallback(
    (address: string): ContractEvent[] | undefined => {
      if (!events) {
        return undefined;
      }
      return events.filter(
        (event) =>
          (event.event_from && accountsEqual(event.event_from, address)) ||
          (event.event_to && accountsEqual(event.event_to, address)));
    },
    [events]
  );
  

  // Fetch events when the component mounts
  React.useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <ContractEventsContext.Provider value={{
      events,
      setEvents,
      fetchEvents,
      getEventsByTokenId,
      getEventsByAccount
    }}>
      {children}
    </ContractEventsContext.Provider>
  );
};

export const useContractEvents = () => {
  return React.useContext(ContractEventsContext);
};
