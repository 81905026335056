import * as React from "react";
import { useMemoOne } from "use-memo-one";
import { ExploreController, RenderingSettings } from "./ExploreController";
import { RobobotsScene } from "./RobobotsScene";

export interface SceneFactory {
  readonly settings: RenderingSettings;
  create(controller: ExploreController): RobobotsScene;
}

export interface ExploreContextState {
  controller: ExploreController;
  showWebGLModal: boolean;
  setShowWebGLModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExploreContext = React.createContext<ExploreContextState>(
  undefined as any
);

export const ExploreContextProvider: React.FC<{
  children: React.ReactNode;
  factory: SceneFactory;
  data?: any;
}> = ({ children, factory, data }) => {
  const controller = useMemoOne(() => new ExploreController(factory), [
    factory,
  ]);
  const [showWebGLModal, setShowWebGLModal] = React.useState(false);

  React.useEffect(() => {
    if (controller && controller.webGLStatus === "success" && data) {
      controller.load(data);
      setShowWebGLModal(false);
    } else if (controller && controller.webGLStatus === "failure") {
      setShowWebGLModal(true);
    }
  }, [data, controller]);

  React.useEffect(() => {
    setShowWebGLModal(controller.webGLStatus === "failure");
  }, [controller.webGLStatus, setShowWebGLModal]);

  return (
    <ExploreContext.Provider value={{ controller, showWebGLModal, setShowWebGLModal }}>
      {children}
    </ExploreContext.Provider>
  );
};

export const EnableWebGLModal: React.FC = () => {
  const { showWebGLModal, setShowWebGLModal } = React.useContext(ExploreContext);

  const onClose = () => {
    setShowWebGLModal(false);
  };

  if (!showWebGLModal) {
    return null;
  }
  return (
    <div className="modal-overlay">
      <div className="modal flex flex-col align-start bg-back1 border-light">
        <div className="flex flex-col justify-start items-center mb-2">
          <img src="question_bot.png" alt="question bot" className="h-24 w-24 mb-2" />
          <span className="text-cyberyellow text-18">Enable WebGL</span>
        </div>
        <div className="text-light text-14 text-center mb-2">
          WebGL is not supported by your device! Please enable hardware acceleration in your browser settings
          (<a className="underline" href="https://www.google.com/search?q=chrome+enable+hardware+acceleration" target="_blank" rel="noopener noreferrer">Chrome</a>,
          <a className="underline" href="https://www.google.com/search?q=firefox+enable+hardware+acceleration" target="_blank" rel="noopener noreferrer">Firefox</a>,
          <a className="underline" href="https://www.google.com/search?q=edge+enable+hardware+acceleration" target="_blank" rel="noopener noreferrer">Edge</a>) and reload the page.
        </div>

        <div className="flex flex-row w-full justify-end">
          <button className="modal-close-button bg-back1 text-14" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};