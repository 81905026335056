import * as React from "react";
import { ExploreContext } from "./ExploreContext";

function useThreeView() {
  const { controller, setShowWebGLModal } = React.useContext(ExploreContext);
  const threeRef = React.useRef<HTMLDivElement>(null);
  const overlayRef = React.useRef<HTMLDivElement>(null);

  // mounts and unmounts controller
  React.useEffect(() => {
    if (controller) {
      controller.initialize(threeRef.current, overlayRef.current);
      if (controller.webGLStatus === "failure") {
        setShowWebGLModal(true);
      }
    }
  }, [controller, setShowWebGLModal]);

  return { threeRef, overlayRef };
}

export const ThreeFullscreenView = () => {
  const { threeRef, overlayRef } = useThreeView();

  return (
    <div className="three-canvas flex w-full h-full">
      <div className="three-render-window relative flex w-full h-full" ref={threeRef}>
        <div className="three-canvas-overlay absolute flex w-full h-full" ref={overlayRef} />
      </div>
    </div>
  );
};
