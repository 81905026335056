import BN from "bn.js";
import Web3 from "web3";

const web3 = new Web3(Web3.givenProvider);

export function isNonZeroValue(weiString: string | undefined): boolean {
  if (!weiString) return false;
  const weiBN = new BN(weiString);
  return weiBN.gt(new BN(0));
}

// format ether value to 3 decimal places or three sig figs, whichever is more precise.
// then drop trailing zeroes behind the decimal place.
export const formatEtherValue = (etherValue: number) => {
  const sigFigs = 3;
  const exp = Math.floor(Math.log10(etherValue));
  const precision = Math.max(sigFigs - 1 - exp, 0);
  const formattedValue = etherValue.toFixed(precision);
  return Number(formattedValue).toString();
};

export const formatWeiAsEther = (weiString: string | undefined) => {
  const hasValue = isNonZeroValue(weiString);
  return hasValue ?
    `${formatEtherValue(parseFloat(web3.utils.fromWei(weiString || "0", "ether"))) } ETH` :
    "N/A";
}

export const compareWeiStrings = (weiString1: string, weiString2: string): number => {
  const bn1 = new BN(weiString1);
  const bn2 = new BN(weiString2);

  if (bn1.gt(bn2)) {
    return 1; // weiString1 is greater than weiString2
  } else if (bn1.lt(bn2)) {
    return -1; // weiString1 is less than weiString2
  } else {
    return 0; // weiString1 is equal to weiString2
  }
};