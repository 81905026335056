import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { isNonZeroValue, formatWeiAsEther, useMediaQuery, accountsEqual } from "../utils";
import { Robobot, Attributes, IconLocationAdjustments } from "../core";
import { LoadedImageContext } from "../canvas/ImageContext";
import { RobobotTransactionButtons } from "./TransactionButtons";
import { useWallet, useContractEvents } from "../context";

import "./bot.css";

const getAttributes = (bot: Robobot): string[] => {
  const attrList = [];
  if (bot.crown) attrList.push(bot.crown);
  if (bot.eyes) attrList.push(bot.eyes);
  if (bot.right_cheek) attrList.push(bot.right_cheek);
  if (bot.ears) attrList.push(bot.ears);
  if (bot.nose) attrList.push(bot.nose);
  if (bot.mouth) attrList.push(bot.mouth);
  if (bot.neck) attrList.push(bot.neck);
  return attrList;
};

export const RobobotView: React.FC<{
  bot?: Robobot;
  setSelectedBot: (bot: Robobot | undefined) => void;
  filteredBots: Robobot[] | undefined;
  close: (e: React.MouseEvent) => void;
  prevPageName: string;
}> = ({ bot, setSelectedBot, filteredBots, close, prevPageName }) => {
  const [attributes, setAttributes] = React.useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);
  const { account } = useWallet();
  const location = useLocation();

  const getNextIndex = React.useCallback(() => {
    if (!filteredBots || selectedIndex === undefined) {
      return undefined;
    }
    return (selectedIndex + 1) % filteredBots.length;
  }, [filteredBots, selectedIndex]);

  const getPreviousIndex = React.useCallback(() => {
    if (!filteredBots || selectedIndex === undefined) {
      return undefined;
    }
    return (selectedIndex - 1 + filteredBots.length) % filteredBots.length;
  }, [filteredBots, selectedIndex]);

  const handlePreviousButtonClick = React.useCallback(() => {
    const prevIndex = getPreviousIndex();
    if (prevIndex !== undefined) {
      setSelectedBot(filteredBots![prevIndex]);
    }
  }, [filteredBots, getPreviousIndex, setSelectedBot]);

  const handleNextButtonClick = React.useCallback(() => {
    const nextIndex = getNextIndex();
    if (nextIndex !== undefined) {
      setSelectedBot(filteredBots![nextIndex]);
    }
  }, [filteredBots, getNextIndex, setSelectedBot]);

  // key shortcuts
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'x') {
        close(event as any);
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault();
        event.stopPropagation();
        handlePreviousButtonClick();
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        event.stopPropagation();
        handleNextButtonClick();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [close, handlePreviousButtonClick, handleNextButtonClick]);

  React.useEffect(() => {
    if (bot) setAttributes(getAttributes(bot));
  }, [bot]);

  React.useEffect(() => {
    if (bot && filteredBots) {
      const index = filteredBots.findIndex((filteredBot) => filteredBot.id === bot.id);
      setSelectedIndex(index);
    } else {
      setSelectedIndex(undefined);
    }
  }, [bot, filteredBots]);

  return (
    <div className="z-20 absolute flex w-full h-full bg-overlay1 backdrop-filter backdrop-blur">
      <div id="whole" className="absolute flex flex-col md:flex-row items-center justify-center md:justify-start h-full w-full">
        <div className="flex absolute top-4 right-4 rounded-full bg-back1 p-1">
          <button className="flex close-button top-4 right-4 text-cybergreen text-12 md:text-14" onClick={close}>
            [x]
          </button>
        </div>
        <div id="left-bot" className="flex flex-col w-full md:w-1/2 md:max-w-400 h-1/3 md:h-full md:bg-white justify-end border-b-1 border-cybergreen md:border-none">
          <Banner bot={bot} />
          <div className="hidden md:flex flex-grow w-full"></div>
          <div className="flex flex-row w-full max-h-full md:h-3/4 justify-center items-center md:items-end md:bg-white">
            <img
              src={`https://robobots-f79f8.web.app//raw_images/bot${bot?.name}.png`}
              alt={`ROBOBOT ${bot?.name}`}
              className="flex w-auto md:w-full h-4/5 md:h-auto md:max-h-full object-contain pixelated bg-white border-2 border-cybergreen md:border-none"
            />
          </div>
        </div>
        <div id="right-info" className="flex flex-col w-full md:flex-grow h-full pt-2 md:pt-4 pb-8 px-4 md:px-8 items-start justify-start overflow-auto">
          <div className="flex flex-col w-full mb-2">
            <div className="flex items-center justify-center text-28 text-cybergreen font-bold w-full mb-2">
              <button className="arrow-button inline-block text-28 md:text-48 mr-2" onClick={handlePreviousButtonClick}>
                &lt;
              </button>
              <span className="inline-block text-28 md:text-48 truncate">
                ROBOBOT {bot?.name}
              </span>
              <button className="arrow-button inline-block text-28 md:text-48 ml-2" onClick={handleNextButtonClick}>
                &gt;
              </button>
            </div>
            {bot?.owner && (
              <div className="flex items-center text-12">
                <span className="text-cyberblue mr-2">owned by</span>{" "}
                <Link
                  to={`/account?alt_addr=${bot.owner}`}
                  className={`text-light hover:underline inline-block truncate-text ${bot.owner === account ? '' : 'w-32 md:w-96'}`}
                  onClick={(e) => {
                    if ((location.pathname.toLowerCase() + location.search.toLowerCase()) ===
                      `/account?alt_addr=${bot.owner}`.toLowerCase()) {
                      close(e);
                    }
                  }}

                >
                  {account && accountsEqual(bot.owner, account) ? "you!" : bot.owner}
                </Link>
              </div>
            )}
            <div className="flex items-center text-12">
              <span className="text-cyberblue mr-2">birthday</span>{" "}
              <img src="birthday_icon.png" alt="birthday icon" className="inline-block w-4 h-4 mr-2" />
              <span
                className="text-light inline-block truncate-text w-32 md:w-96">
                6/21/23
              </span>
            </div>
            {isNonZeroValue(bot?.sale_price) && (
              <div className="text-12">
                <span className="text-cyberblue">sale price</span>{" "}
                <span className="text-light">{formatWeiAsEther(bot?.sale_price)}</span>
              </div>
            )}
            {isNonZeroValue(bot?.highest_bid) && (
              <div className="text-12">
                <span className="text-cyberblue">highest bid</span>{" "}
                <span className="text-light">{formatWeiAsEther(bot?.highest_bid)}</span>
              </div>
            )}
          </div>

          <div className="flex flex-col py-2 md:py-6 w-3/4">
            <div className="flex flex-row items-center mb-2 md:mb-6">
              <span className="text-20 text-cybergreen font-bold mr-2">TYPE:{" "}</span>
              <AttributeIcon attributeName={bot?.base || ""} />
              <span className="ml-2 text-16 text-light">{bot?.base}</span>
            </div>
            <div className="text-20 text-cybergreen font-bold mb-3">ATTRIBUTES:</div>
            {attributes.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-y-4 gap-x-4">
                {attributes.map((attribute) => (
                  <div key={attribute} className="text-16 text-light flex items-center">
                    <AttributeIcon attributeName={attribute} />
                    <span className="ml-2">{attribute}</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="md:my-2 text-sm text-light">[no attributes]</div>
            )}
          </div>
          <RobobotTransactionButtons bot={bot} close={close} prevPageName={prevPageName} />
          {bot && bot.id && <BotTransactionHistory botId={bot.id} close={close} />}
        </div>
      </div>
    </div>
  );
};

interface AttributeIconProps {
  attributeName: string;
}

export const AttributeIcon: React.FC<AttributeIconProps> = ({ attributeName }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const imagesLoaded = React.useContext(LoadedImageContext);
  const initSize = 24; // pixels
  const scaleFactor = 3;

  React.useEffect(() => {
    if (imagesLoaded) {
      const attribute = Attributes.find((a) => a.name === attributeName);
      if (attribute && attribute.data.length > 0 && canvasRef.current) {
        const ctx = canvasRef.current.getContext("2d");
        if (ctx) {
          const imageData = new ImageData(attribute.data, initSize, initSize);
          const adjustment = attribute.icon_adjustment || IconLocationAdjustments[attribute.location];

          // Create a temporary canvas to draw the original image
          const tempCanvas = document.createElement("canvas");
          tempCanvas.width = initSize;
          tempCanvas.height = initSize;
          const tempCtx = tempCanvas.getContext("2d");

          if (tempCtx) {
            // Draw the image data on the temporary canvas
            tempCtx.putImageData(imageData, 0, 0);

            // Clear the target canvas and reset the transformation matrix
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            ctx.resetTransform();

            // Scale the context of the target canvas
            ctx.scale(scaleFactor, scaleFactor);
            ctx.imageSmoothingEnabled = false; // pixels need no smoothing

            // Draw the temporary canvas onto the target canvas, with scaling applied
            ctx.drawImage(
              tempCanvas,
              adjustment.x,
              adjustment.y
            );
          }
        }
      }
    }
  }, [attributeName, imagesLoaded]);

  return (
    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-back2 overflow-hidden">
      <canvas ref={canvasRef} width={initSize * scaleFactor} height={initSize * scaleFactor} />
    </div>
  );
};

const Banner: React.FC<{ bot: Robobot | undefined }> = ({ bot }) => {
  if (!bot) {
    return null; // render nothing
  }

  let banner_type: string;
  if (isNonZeroValue(bot.sale_price)) {
    banner_type = "for_sale";
  } else {
    banner_type = "owned";
  } // no more unminted bots

  return (
    <div className="hidden md:flex flex-row justify-left w-full h-1/4">
      <img
        src={`${banner_type}_banner.png`}
        alt={`ROBOBOT ${bot.name} is ${banner_type}!`}
        className="h-auto object-contain"
      />
    </div>
  );
};

interface BotTransactionHistoryProps {
  botId: string;
  close: (e: React.MouseEvent) => void;
}

export const BotTransactionHistory: React.FC<BotTransactionHistoryProps> = ({ botId, close }) => {
  const { getEventsByTokenId } = useContractEvents();
  const isMobileDevice = useMediaQuery('(max-width: 767px)');
  const { account } = useWallet();
  const location = useLocation();

  const formatAddressLink = (address: string | undefined) => {
    if (!address) return '--';
    if (account && (accountsEqual(address, account))) return 'you';
    const sliceLength = isMobileDevice ? 6 : 10;
    return (
      <Link
        to={`/account?alt_addr=${address}`}
        className={`hover:underline`}
        onClick={(e) => {
          if ((location.pathname.toLowerCase() + location.search.toLowerCase()) ===
            `/account?alt_addr=${address}`.toLowerCase()) {
            close(e);
          }
        }}
      >
        {address.slice(0, sliceLength) + '...'}
      </Link>
    );
  };

  const events = getEventsByTokenId(botId);
  if (!events || events.length === 0) {
    return null;
  }

  return (
    <div
      className="flex flex-col w-full h-fit border-2 border-cyberblue mt-16 items-center"
      style={{ minHeight: '128px' /* min-h-32 not working as expected */ }}
    >
      <table className="w-full table-fixed">
        <thead>
          <tr className="text-cybergreen text-12 md:text-16">
            <th className="text-left p-1 md:p-2">Event</th>
            <th className="text-left p-1 md:p-2">From</th>
            <th className="text-left p-1 md:p-2">To</th>
            <th className="text-left p-1 md:p-2">Value</th>
            <th className="text-left p-1 md:p-2">Block</th>
            {/* <th className="text-left p-1 md:p-2">Date</th> */}
          </tr>
        </thead>
      </table>
      <div className="w-full max-h-48 overflow-y-auto">
        <table className="w-full table-fixed">
          <tbody>
            {events.slice().reverse().map((event, index) => (
              <tr key={index} className={`text-8 md:text-12 ${index % 2 === 0 ? 'bg-row-even' : 'bg-row-odd'}`}>
                <td className="p-1 md:p-2">
                  <a className="hover:underline" href={`https://etherscan.io/tx/${event.event_hash}`} target="_blank" rel="noopener noreferrer">
                    {event.event_type}
                  </a>
                </td>
                <td className="p-1 md:p-2" title={event.event_from || ''}>{formatAddressLink(event.event_from)}</td>
                <td className="p-1 md:p-2" title={event.event_to || ''}>{formatAddressLink(event.event_to)}</td>
                <td className="p-1 md:p-2">{event.event_value ? formatWeiAsEther(event.event_value) : "--"}</td>
                <td className="p-1 md:p-2" title={event.event_block.toString()}>{event.event_block}</td>
                {/* <td className="p-1 md:p-2" title={event.event_date}>{formatDate(event.event_date, isMobileDevice)}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
