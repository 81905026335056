import React from "react";
import { Robobot } from "../core";
import { isNonZeroValue, formatWeiAsEther } from "../utils";
import "./bot.css";

interface PreviewBotProps {
  bot: Robobot;
  onClick: () => void;
}

export const PreviewBot: React.FC<PreviewBotProps> = ({ bot, onClick }) => {
  const imageUrl = `https://robobots-f79f8.web.app/raw_images/bot${bot.name}.png`;
  const hasSalePrice = isNonZeroValue(bot?.sale_price);
  const hasHighestBid = isNonZeroValue(bot?.highest_bid);
  const salePrice = formatWeiAsEther(bot?.sale_price);
  const highestBid = formatWeiAsEther(bot?.highest_bid);

  return (
    <div className="w-24 h-24 flex flex-col items-center text-center cursor-pointer" onClick={onClick}>
      <div className="flex flex-col justify-start items-center p-1">
        <img src={imageUrl} alt={`Bot ${bot.name}`} width="48" height="48" />
        <div className="text-12 text-light pt-1">#{bot.name}</div>
        {hasSalePrice && <div className="text-8 text-cybergreen">ASK: {salePrice}</div>}
        {hasHighestBid && <div className="text-8 text-cyberyellow">BID: {highestBid}</div>}
      </div>
    </div>
  );
};