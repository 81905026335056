import React from "react";
import { formatWeiAsEther, isNonZeroValue, CONTRACT_ADDRESS } from "../utils";
import Web3 from "web3";
import BN from "bn.js";


const web3 = new Web3(Web3.givenProvider);
const contractAbiArray = require("../contract/robobots_abi.json");
const robobotsContract = new web3.eth.Contract(contractAbiArray, CONTRACT_ADDRESS);

function usePendingWithdrawals(account: string | null | undefined) {
  const [hasPendingWithdrawals, setHasPendingWithdrawals] = React.useState<boolean>(false);
  const [pendingWithdrawalsEthString, setPendingWithdrawalsEthString] = React.useState<string>("");

  React.useEffect(() => {
    if (account) {
      robobotsContract.methods.pendingWithdrawals(account).call().then((pendingWithdrawalsValue: BN) => {
        const pendingWithdrawalsWeiString = pendingWithdrawalsValue.toString();
        setHasPendingWithdrawals(isNonZeroValue(pendingWithdrawalsWeiString));
        setPendingWithdrawalsEthString(formatWeiAsEther(pendingWithdrawalsWeiString));
      });
    } else {
      setHasPendingWithdrawals(false);
      setPendingWithdrawalsEthString("");
    }
  }, [account]);

  return { hasPendingWithdrawals, pendingWithdrawalsEthString };
}

interface WalletContextType {
  showInstallMetamaskModal: boolean;
  setShowInstallMetamaskModal: (show: boolean) => void;
  account: string | null | undefined;
  accountBalanceEth: string | null | undefined;
  handleConnect: () => void;
  hasPendingWithdrawals: boolean;
  pendingWithdrawalsEthString: string;
}

const WalletContext = React.createContext<WalletContextType>({
  showInstallMetamaskModal: false,
  setShowInstallMetamaskModal: () => { },
  account: null,
  accountBalanceEth: null,
  handleConnect: () => { },
  hasPendingWithdrawals: false,
  pendingWithdrawalsEthString: "",
});

export const WalletContextProvider: React.FC = ({ children }) => {
  const [showInstallMetamaskModal, setShowInstallMetamaskModal] = React.useState(false);
  const [account, setAccount] = React.useState<string | null>(null);
  const [accountBalanceEth, setAccountBalanceEth] = React.useState<string | null>(null);
  const { hasPendingWithdrawals, pendingWithdrawalsEthString } = usePendingWithdrawals(account);

  const handleConnect = React.useCallback(() => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts: string[]) => {
          setAccount(accounts[0]);
        })
        .catch((error: Error) => {
          console.error("Error connecting to browser wallet: ", error);
        });
    } else {
      setShowInstallMetamaskModal(true);
    }
  }, []);

  // Re-render when the user switches accounts
  React.useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts: string[]) => {
        setAccount(accounts[0]);
      };
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      // Clean up the subscription
      return () => {
        window.ethereum.off("accountsChanged", handleAccountsChanged);
      };
    }
  }, []);

  React.useEffect(() => {
    if (account) {
      web3.eth.getBalance(account).then((balance) => {
        setAccountBalanceEth(formatWeiAsEther(balance.toString()));
      });
    } else {
      setAccountBalanceEth(null);
    }
  }, [account]);

  const walletContextValue = React.useMemo(
    () => ({
      showInstallMetamaskModal,
      setShowInstallMetamaskModal,
      account,
      accountBalanceEth,
      handleConnect,
      hasPendingWithdrawals,
      pendingWithdrawalsEthString,
    }),
    [
      showInstallMetamaskModal,
      setShowInstallMetamaskModal,
      account,
      accountBalanceEth,
      handleConnect,
      hasPendingWithdrawals,
      pendingWithdrawalsEthString,
    ]
  );

  return (
    <WalletContext.Provider value={walletContextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  return React.useContext(WalletContext);
};

export const InstallMetamaskModal: React.FC = () => {

  const { showInstallMetamaskModal, setShowInstallMetamaskModal } = useWallet();

  const onClose = () => {
    setShowInstallMetamaskModal(false);
  };

  if (!showInstallMetamaskModal) {
    return null;
  }
  return (
    <div className="modal-overlay">
      <div className="modal flex flex-col align-start bg-back1 border-light">
        <div className="flex flex-row justify-start items-center mb-3">
          <img src="metamask.png" alt="MetaMask Logo" className="mr-2 h-10 w-10" />
          <span className="text-cyberorange text-18">Install MetaMask</span>
        </div>
        <div className="text-light text-14">
          To connect your account, please install{' '}
          <a href="https://metamask.io/" target="_blank" rel="noreferrer" className="underline">
            MetaMask
          </a>
          , a popular Ethereum wallet.
        </div>
        <div className="flex flex-row w-full justify-end">
          <button className="modal-close-button bg-back1 text-14" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};