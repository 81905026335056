import React from 'react';
import { Observable } from 'rxjs';

export function useObservable<T>(
  factory: () => Observable<T> | undefined,
  deps: React.DependencyList,
): [T | undefined, any] {
  const [value, setValue] = React.useState<T>();
  const [error, setError] = React.useState<any>();
  React.useEffect(() => {
    const observable = factory();
    if (!observable) {
      return;
    }

    const subscription = observable.subscribe({
      next: (x) => {
        setValue(x);
      },
      error: (err) => {
        setValue(undefined);
        setError(err);
      },
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [value, error];
}
