import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";

import { Doodle } from "./doodle";
import { About } from "./about";
import { Explore } from "./explore";
import { Account } from "./account";
import { ImageContextProvider } from "./canvas";
import {
  RobobotsContextProvider,
  ContractEventsContextProvider,
  WalletContextProvider,
  useWallet,
  InstallMetamaskModal,
} from "./context";

import "./App.css";
import { accountsEqual } from "./utils";

export const SiteShell: React.FC = ({ children }) => {
  return (
    <div className="rb-site-container absolute flex flex-col w-full h-full text-white bg-back1">
      <Header />
      {children}
    </div>
  );
};

export const NavButton: React.FC<{
  to?: string;
  label: string;
  active: boolean;
  imgSrc?: string;
  onClick?: () => void;
}> = ({ to, label, active, imgSrc, onClick }) => {
  const history = useHistory();

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
      if (to) {
        // Use 'history' from react-router-dom to navigate to the desired page
        // this doesn't happen automatically when both 'to' and 'onClick' are defined.
        history.push(to);
      }
    }
  };

  return (
    <div>
      <Link
        to={to || "#"}
        className={`flex flex-row px-4 py-2 text-28 items-center ${active ? "rb-home-button-active" : "rb-home-button"}`}
        onClick={handleClick}
      >
        {imgSrc && <img src={imgSrc} alt="" />}
        <span>{label}</span>
      </Link>
    </div>
  );
};

export const Header: React.FC = () => {
  const location = useLocation();
  const { account, handleConnect } = useWallet();
  const isHomePage =
    location.pathname === "/" || location.pathname.startsWith("/explore");
  const params = new URLSearchParams(location.search);
  const altAddress = params.get('alt_addr');

  return (
    <>
      <div className="header-full h-20 min-h-20 flex flex-row justify-between items-center px-8 border-solid border-b-1">
        <Link to="/">
          <div
            className={`flex flex-row items-center text-white text-40 ${isHomePage ? "rb-home-title-active" : "rb-home-title"
              }`}
          >
            <span className="inline mr-1 logo-image"></span>
            robobots
          </div>
        </Link>
        <div className="header-right">
          <NavButton
            to="/account"
            label="account"
            active={location.pathname === "/account" && (!altAddress || accountsEqual(altAddress, account))}
            imgSrc="account_icon.png"
            onClick={!account ? handleConnect : undefined}
          />
          <NavButton
            to="/doodle"
            label="doodle"
            active={location.pathname === "/doodle"}
            imgSrc="doodle_icon.png"
          />
          <NavButton
            to="/about"
            label="about"
            active={location.pathname === "/about"}
            imgSrc="about_icon.png"
          />
        </div>
      </div>
      <InstallMetamaskModal />
    </>
  );
};

export const App: React.FC = () => {
  return (
    <ImageContextProvider>
      <RobobotsContextProvider>
        <ContractEventsContextProvider>
          <WalletContextProvider>
            <Router>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={({ location }) => (
                    <Redirect
                      to={{
                        ...location,
                        pathname: "/explore",
                      }}
                    />
                  )}
                />
                <Route exact path="/explore">
                  <SiteShell>
                    <Explore />
                  </SiteShell>
                </Route>
                <Route exact path="/account">
                  <SiteShell>
                    <Account />
                  </SiteShell>
                </Route>
                <Route exact path="/doodle">
                  <SiteShell>
                    <Doodle />
                  </SiteShell>
                </Route>
                <Route exact path="/about">
                  <SiteShell>
                    <About />
                  </SiteShell>
                </Route>
              </Switch>
            </Router>
          </WalletContextProvider>
        </ContractEventsContextProvider>
      </RobobotsContextProvider>
    </ImageContextProvider>
  );
};
