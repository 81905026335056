import React from "react";
import { Robobot } from "../core";
import { RobobotView } from "./RobobotView";

interface RobobotOverlayProps {
  filteredBots: Robobot[] | undefined;
  selectedBot: Robobot | undefined;
  setSelectedBot: (bot: Robobot | undefined) => void;
  prevPageName: string;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const RobobotOverlay: React.FC<RobobotOverlayProps> = ({
  filteredBots,
  selectedBot,
  setSelectedBot,
  prevPageName,
  containerRef,
}) => {
  const close = (e: React.MouseEvent) => {
    setSelectedBot(undefined);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      {selectedBot && (
        <div className="z-50 absolute w-full h-full"
          style={{
            top: containerRef.current?.scrollTop || 0,
            left: containerRef.current?.scrollLeft || 0,
          }}>
          <RobobotView
            bot={selectedBot}
            setSelectedBot={setSelectedBot}
            filteredBots={filteredBots}
            close={close}
            prevPageName={prevPageName}
          />
        </div>
      )}
    </>
  );
};
