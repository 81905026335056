import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Robobot } from "../core";
import { useWallet, useRobobots, useContractEvents } from "../context";
import { filterRobobot, blankRobobotFilter, useMediaQuery, formatWeiAsEther, accountsEqual } from "../utils";
import { RedeemButton, PreviewBot, RobobotOverlay } from "../bot";
import "./account.css";

const ALT_ADDR = "alt_addr";

function useQuery() {
  const location = useLocation();
  return React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
}

export const Account: React.FC = () => {
  const { handleConnect, account } = useWallet();
  const { robobots } = useRobobots();
  const [ownedRobobots, setOwnedRobobots] = React.useState<Robobot[]>();
  const [bidRobobots, setBidRobobots] = React.useState<Robobot[]>();
  const [selectedBot, setSelectedBot] = React.useState<Robobot | undefined>(undefined);
  const [filteredBots, setFilteredBots] = React.useState<Robobot[] | undefined>();
  const [displayAccount, setDisplayAccount] = React.useState<string | null>(account || null);

  const query = useQuery();

  // update displayAccount when alternate address changes
  React.useEffect(() => {
    const altAddress = query.get(ALT_ADDR);
    if (altAddress) {
      setDisplayAccount(altAddress);
      setSelectedBot(undefined);
    }
  }, [query, setDisplayAccount]);

  // update displayAccount when account changes
  React.useEffect(() => {
    const altAddress = query.get(ALT_ADDR);
    if (!altAddress && account) {
      setDisplayAccount(account);
    }
  }, [account, query, setDisplayAccount]);

  React.useEffect(() => {
    if (robobots && displayAccount) {
      // filter on owner
      const ownedFilter = { ...blankRobobotFilter };
      ownedFilter.owner = displayAccount;
      setOwnedRobobots(
        robobots.filter((bot) => filterRobobot(bot, ownedFilter)));
      // filter on bidder
      const bidFilter = { ...blankRobobotFilter };
      bidFilter.bidder = displayAccount;
      setBidRobobots(
        robobots.filter((bot) => filterRobobot(bot, bidFilter)));
    }
  }, [robobots, displayAccount]);

  const handlePreviewBotClick = React.useCallback(
    (bot: Robobot, botsArray: Robobot[] | undefined) => {
      setSelectedBot(bot);
      setFilteredBots(botsArray);
    }, []);

  const renderBotsSection = (title: string, botsArray: Robobot[] | undefined) => {
    const hasBots = botsArray && botsArray.length > 0;
    const botsCount = hasBots ? ` (${botsArray!.length})` : "";
    const sectionTitle = title + botsCount;
    return (
      <div>
        <div className="text-20 md:text-24 my-2">{sectionTitle}</div>
        {botsArray === undefined ? (
          <div className="flex flex-row justify-center">
            {Array.from({ length: 5 }, (_, i) => (
              <img key={i} src="protobot_shards.gif" alt="loading" className="h-20 w-20 mx-2" />
            ))}
          </div>
        ) : hasBots ? (
          <div className="border-2 border-cybergreen bg-back2 overflow-auto h-56">
            <div className="account-bot-grid grid grid-cols-2 md:grid-cols-4">
              {botsArray.map((bot) => (
                <PreviewBot key={bot.name} bot={bot} onClick={() => handlePreviewBotClick(bot, botsArray)} />
              ))}
            </div>
          </div>
        ) : (
          <div className="text-14 text-cybergreen mt-2">&lt;none&gt;</div>
        )}
      </div>
    );
  };

  const accountContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div ref={accountContainerRef} className={`account-container relative ${selectedBot ? "overflow-hidden" : "overflow-auto"}`}>
      <div className="left-of-account" />
      <div className="account-view p-1 pb-4 md:p-4">
        {displayAccount ? (
          <div className="flex flex-col w-full items-start mt-1 md:mt-4">
            <AccountSummary
              numOwnedRobobots={ownedRobobots?.length || 0}
              displayAccount={displayAccount}
            />
            <div className="your-bots w-full my-4">
              {renderBotsSection(`${accountsEqual(displayAccount, account)
                ? "Your"
                : displayAccount.slice(0, 7) + "...'s"} Bots`, ownedRobobots)}
            </div>
            <div className="your-bids w-full my-4">
              {renderBotsSection(`${accountsEqual(displayAccount, account)
                ? "Your"
                : displayAccount.slice(0, 7) + "...'s"} Bids`, bidRobobots)}
            </div>
            <div className="account-history-container w-full my-4">
              <div className="text-20 md:text-24 my-2">Recent Transactions</div>
              <AccountTransactionHistory
                account={account}
                displayAccount={displayAccount}
                setSelectedBot={setSelectedBot}
                setFilteredBots={setFilteredBots} />
            </div>
            <div className="flex flex-row w-full mt-8 justify-center">
              <img className="w-24 h-24 mt-96" src="robobot_wallet.png" alt="robobots wallet art" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full h-1/2 items-center justify-center">
            <img className="w-24 h-24 m-2" src="robobot_wallet.png" alt="robobots wallet art" />
            <button className="flex transaction-button w-auto h-100 m-2" onClick={handleConnect}>
              Connect Wallet
            </button>
          </div>
        )}
      </div>
      <div className="right-of-account" />
      <RobobotOverlay
        filteredBots={filteredBots}
        selectedBot={selectedBot}
        setSelectedBot={setSelectedBot}
        prevPageName="account"
        containerRef={accountContainerRef}
      />
    </div>
  );
};

// Define props for AccountSummary
type AccountSummaryProps = {
  numOwnedRobobots: number,
  displayAccount: string | null,
};

export const AccountSummary: React.FC<AccountSummaryProps> = ({
  numOwnedRobobots,
  displayAccount,
}) => {

  const [inputAccount, setInputAccount] = React.useState<string>(displayAccount || "");
  const {
    account,
    accountBalanceEth,
    hasPendingWithdrawals,
    pendingWithdrawalsEthString,
    handleConnect
  } = useWallet();

  // Get account from URL
  const history = useHistory();

  // update inputAccount when displayAccount changes
  React.useEffect(() => {
    if (displayAccount) {
      setInputAccount(displayAccount);
    }
  }, [displayAccount, setInputAccount]);


  const isValidAddress = (address: string | null) => {
    return address && address.startsWith('0x') && address.length === 42; // basic validation for now
  };

  const handleSearchClick = () => {
    if (isValidAddress(inputAccount)) {
      history.push(`/account?${ALT_ADDR}=${inputAccount}`);
    }
  };

  const handleMyAccountClick = () => {
    if (account) {
      history.push(`/account?${ALT_ADDR}=${account}`);
      setInputAccount(account);
    }
  };

  const etherscanLink = `https://etherscan.io/address/${displayAccount}`;
  const searchEnabled = isValidAddress(inputAccount) && !accountsEqual(displayAccount, inputAccount);
  const myAccountEnabled = !accountsEqual(displayAccount, account);
  const accountButtonStyle = "inline md:mr-2 my-1 md:my-0 px-3.5 border-2 rounded-lg";
  const enabledButtonStyle = `${accountButtonStyle} text-cybergreen border-cybergreen hover:bg-cybergreen hover:text-back1 hover:font-bold`;
  const disabledButtonStyle = `${accountButtonStyle} text-robogrey border-robogrey cursor-default`;

  return (
    <div className="flex flex-col items-start my-2 p-1">
      <div className="flex flex-col md:flex-row items-left md:items-center text-14">
        <span className="md:mr-2 my-1 md:my-0">ethereum_address:{" "}</span>
        <input className="text-cyberblue bg-back2 rounded p-1 truncate-text w-40 md:w-24 md:w-auto my-0.5 md:my-0 md:mr-2" value={inputAccount} onChange={(e) => setInputAccount(e.target.value)} />
        <button
          className={searchEnabled ? enabledButtonStyle : disabledButtonStyle}
          disabled={!searchEnabled}
          onClick={handleSearchClick}
        >
          Search
        </button>
        {account ? (
          <button
            className={myAccountEnabled ? enabledButtonStyle : disabledButtonStyle}
            disabled={!myAccountEnabled}
            onClick={handleMyAccountClick}
          >
            My Account
          </button>
        ) : (
          <button
            className={enabledButtonStyle}
            onClick={handleConnect}>
            Connect Wallet
          </button>
        )}

      </div>
      <div className="flex flex-row text-14 my-1">
        <div className="mr-2">num_robobots_owned: </div>
        <div className="text-cybergreen">
          {numOwnedRobobots || "0"}
        </div>
      </div>
      <div className="flex flex-row text-14 my-1">
        {accountsEqual(displayAccount, account) ? (
          <>
            <div className="mr-2">ether_balance:</div>
            <div className="text-cybergreen">
              {accountBalanceEth || "???"}
            </div>
          </>
        ) : (
          <a className="text-cybergreen underline" href={etherscanLink} target="_blank" rel="noopener noreferrer">View Account on Etherscan</a>
        )}
      </div>
      {hasPendingWithdrawals && <RedeemButton pendingWithdrawalsEthString={pendingWithdrawalsEthString} />}
    </div>
  );
};

export default React.memo(AccountSummary);

interface AccountTransactionHistoryProps {
  account: string | undefined | null;
  displayAccount: string | undefined | null;
  setSelectedBot: React.Dispatch<React.SetStateAction<Robobot | undefined>>;
  setFilteredBots: React.Dispatch<React.SetStateAction<Robobot[] | undefined>>;
}

export const AccountTransactionHistory: React.FC<AccountTransactionHistoryProps> = (
  { account, displayAccount, setSelectedBot, setFilteredBots }) => {

  const [currentPage, setCurrentPage] = React.useState(1);
  const { getEventsByAccount } = useContractEvents();
  const { robobots } = useRobobots();

  const isMobileDevice = useMediaQuery('(max-width: 767px)');
  const itemsPerPage = 25;

  const formatAddressLink = React.useCallback((address: string | undefined) => {
    if (!address) return '--';
    if (account && accountsEqual(displayAccount, account) && accountsEqual(address, account)) return 'you';
    const sliceLength = isMobileDevice ? 6 : 8;
    return (
      <Link
        to={`/account?alt_addr=${address}`}
        className={`hover:underline`}
      >
        {address.slice(0, sliceLength) + '...'}
      </Link>
    );
  }, [displayAccount, account, isMobileDevice]);

  const handlePreviewBotClick = React.useCallback(
    (bot: Robobot, botsArray: Robobot[] | undefined) => {
      setSelectedBot(bot);
      setFilteredBots(botsArray);
    }, [setSelectedBot, setFilteredBots]);

  if (!displayAccount || !robobots) {
    return null;
  }

  const events = getEventsByAccount(displayAccount);
  if (!events || events.length === 0) {
    return (<div className="text-14 text-cybergreen mt-2">&lt;none&gt;</div>);
  }

  const totalPages = Math.ceil(events.length / itemsPerPage);
  const hasPrevPage = currentPage !== 1;
  const hasNextPage = currentPage !== totalPages;
  const currentEvents = events.slice().reverse().slice(
    (currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  let botIds = currentEvents.map((event) => Number(event.id));
  let botsArray = botIds.map(id => robobots[id]);

  return (
    <div className="account-transaction-history flex flex-col w-full mt-4 items-center">
      <div className="border-2 border-cybergreen">
        <table className="w-full table-fixed">
          <thead>
            <tr className="text-cyberblue text-14 md:text-16">
              <th className="text-left p-1 md:p-2 w-1/12">Event</th>
              <th className="text-center p-1 md:p-2 w-1/2 md:w-1/3">Bot</th>
              <th className="hidden md:table-cell text-left p-1 md:p-2">From</th>
              <th className="hidden md:table-cell text-left p-1 md:p-2">To</th>
              <th className="text-left p-1 md:p-2">Value</th>
              <th className="text-left p-1 md:p-2">Block</th>
              {/* <th className="text-left p-1 md:p-2">Date</th> */}
            </tr>
          </thead>
        </table>
        <div className={`w-full max-h-96 overflow-y-auto ${isMobileDevice ? 'overflow-x-auto' : ''}`}>
          <table className="w-full table-fixed">
            <tbody>
              {currentEvents.map((event, index) => {
                const bot = robobots[Number(event.id)];
                return (
                  <tr key={index} className={`text-10 md:text-12 ${index % 2 === 0 ? 'bg-row-even' : 'bg-row-odd'}`}>
                    <td className="p-1 md:p-2 w-1/12">
                      <a className="hover:underline" href={`https://etherscan.io/tx/${event.event_hash}`} target="_blank" rel="noopener noreferrer">
                        {event.event_type}
                      </a>
                    </td>
                    <td className="p-1 md:p-2 w-1/2 md:w-1/3">
                      <div className="flex justify-center items-center">
                        <PreviewBot key={bot.name} bot={bot} onClick={() => handlePreviewBotClick(bot, botsArray)} />
                      </div>
                    </td>
                    <td className="hidden md:table-cell p-1 md:p-2" title={event.event_from || ''}>{formatAddressLink(event.event_from)}</td>
                    <td className="hidden md:table-cell p-1 md:p-2" title={event.event_to || ''}>{formatAddressLink(event.event_to)}</td>
                    <td className="p-1 md:p-2">{event.event_value ? formatWeiAsEther(event.event_value) : "--"}</td>
                    <td className="p-1 md:p-2" title={event.event_block.toString()}>{event.event_block}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-between p-1 md:p-2">
      <button className={`text-cybergreen mx-2 ${hasPrevPage ? "page-button" : "page-button-disabled"}`}
          disabled={!hasPrevPage}
          onClick={() => setCurrentPage(currentPage - 1)}>
          &lt; Prev
        </button>
        <div className="text-light">|</div>
        <button className={`text-cybergreen mx-2 ${hasNextPage ? "page-button" : "page-button-disabled"}`}
          disabled={!hasNextPage}
          onClick={() => setCurrentPage(currentPage + 1)}>
          Next &gt;
        </button>
      </div>
    </div>
  );
};