import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import Stats from "stats.js";
import { OrbitControls } from "./OrbitControls";
import { SceneFactory } from "./ExploreContext";
import { RobobotsScene } from "./RobobotsScene";

export type RenderingSettings = {
  alwaysDraw: boolean;
  showStats: boolean;
};

export type Dimensions = {
  height: number;
  width: number;
  aspect: number;
};

export type DrawState = {
  animationRequestId: number | undefined;
  drawRequested: boolean;
};

export class ExploreController {
  // window
  private container!: HTMLDivElement;
  overlay!: HTMLDivElement;
  dims!: Dimensions;
  renderer!: THREE.WebGLRenderer;
  private renderingSettings: RenderingSettings;
  composer!: EffectComposer;

  // scene
  scene: THREE.Scene;
  robobotsScene: RobobotsScene;

  // objects
  camera!: THREE.PerspectiveCamera;
  orbitControls!: OrbitControls;

  // state
  private clock: THREE.Clock;
  private drawState: DrawState;
  public webGLStatus: "success" | "failure" | "undefined" = "undefined";

  stats: Stats;

  constructor(factory: SceneFactory) {
    // window
    this._updateDimensions(window.innerWidth, window.innerHeight);
    this.renderingSettings = factory.settings;

    // scene
    this.scene = new THREE.Scene();
    this.robobotsScene = factory.create(this);

    // state
    this.clock = new THREE.Clock();
    this.drawState = {
      animationRequestId: undefined,
      drawRequested: false,
    };

    this.stats = new Stats();
    // uncomment to show stats with three.js performance
    // this.stats.showPanel(1);
    // document.body.appendChild(this.stats.dom);
  }

  initialize(
    containerElement: HTMLDivElement | null,
    overlayElement: HTMLDivElement | null
  ) {
    if (containerElement && overlayElement) {
      // window
      this.container = containerElement;
      this.overlay = overlayElement;
      this._updateDimensions(
        this.container.offsetWidth,
        this.container.offsetHeight
      );

      // renderer
      this._setupRenderer();

      // listeners
      this._setupListeners();

      this.requestDraw();
    }

    // start the animation
    this.drawState.animationRequestId = requestAnimationFrame(this.update);

    return () => {
      // stop the animation
      if (this.drawState.animationRequestId) {
        cancelAnimationFrame(this.drawState.animationRequestId);
        this.drawState.animationRequestId = undefined;
      }

      this._removeListeners();
    };
  }

  load = (data: any) => {
    this.robobotsScene.setData(data);
  };

  update = () => {
    // const delta = this.clock.getDelta();

    this.stats.begin();

    let controlsUpdated = false;
    if (this.orbitControls) {
      controlsUpdated = this.orbitControls.update();
    }

    this.robobotsScene.update();

    this.drawState.animationRequestId = requestAnimationFrame(this.update);

    if (this.drawState.drawRequested || controlsUpdated) {
      this.draw();
    }

    this.stats.end();
  };

  draw = () => {
    if (this.renderer && this.scene && this.camera) {
      this.composer.render();
    }

    this.drawState.drawRequested = this.renderingSettings.alwaysDraw;
  };

  _setupRenderer = () => {
    const { width, height } = this.dims;
    try {
      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      this.webGLStatus = "success";
    } catch (error) {
      this.webGLStatus = "failure";
      return;
    }
    // set background color if you want
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.container.appendChild(this.renderer.domElement);

    // effect composer
    this.composer = new EffectComposer(this.renderer);

    if (this.renderingSettings.showStats) {
      document.body.appendChild(this.stats.dom);
    }
  };

  requestDraw = () => {
    this.drawState.drawRequested = true;
  };

  _onControlsUpdate = () => {
    this.requestDraw();
  };

  _onWindowResize = () => {
    if (this.container && this.camera && this.renderer) {
      this._updateDimensions(
        this.container.offsetWidth,
        this.container.offsetHeight
      );
      const { width, height, aspect } = this.dims;
      this.camera.aspect = aspect;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);

      // update 2D canvas elements
      this.setCanvasDimensions(this.renderer.domElement, width, height);

      this.requestDraw();
    }
  };

  setCanvasDimensions = (
    canvas: HTMLCanvasElement,
    width: number,
    height: number,
    set2dTransform = false
  ) => {
    const ratio = window.devicePixelRatio;
    canvas.width = width * ratio;
    canvas.height = height * ratio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    if (set2dTransform) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
      }
    }
  };

  _setupListeners = () => {
    window.addEventListener("resize", this._onWindowResize);
  };

  _removeListeners = () => {
    window.removeEventListener("resize", this._onWindowResize);
  };

  /* UPDATE */

  _updateDimensions = (width: number, height: number) => {
    this.dims = {
      width: width,
      height: height,
      aspect: width / height,
    };
  };
}
