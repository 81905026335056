import React from "react";
import "./about.css";
import axios from 'axios';

const hotbotEmojiSrc = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABEElEQVRIS2NkoDFgpLH5DKMWEAxhXEH0n6BOTAVYzcIm+P///70km8/I6AzSg2Eedgv+QS0AyYL8AqPxWMvIRIoFX8jwAQ8pFrwiwwIxUix4QIYFCqRYcA3JgtK/DH/m6TKwvL2GGgNo4oxaxFnw//8ZNNdn/2H4s0mPgeUxmgVYxBlNMC1BT0X//x8mPXhgXmO0JcICkOL/O0m3hNEdbDhGXsCeD9bvIT2jBbqQkNGWkmFBNCkWzCbDglRSLJi4GzWI6v5gD7ImFrg4Y74rCUHUjmYBETHCWEmKBbVkBFEz8UEETqnIjv5fjOojxl6wa9EB0fUBusb//zNRfcQ4HbtribYVTeH///FoFiwk3gIACR5hGUEYtvsAAAAASUVORK5CYII=";
const emoji = <img src={hotbotEmojiSrc} alt="hotbot emoji" className="object-contain h-8 w-8" />;

// AboutForm injects a recaptcha script into the document,
// which uses this variable, which in turn is used to set
// form data. Chat said so, okay?
declare const grecaptcha: any;

declare global {
  interface Window {
    recaptchaCallback: (response: string) => void;
    recaptchaExpiredCallback: (response: string) => void;
  }
}

export const About: React.FC = () => {
  return (
    <div className="about-container">
      <div className="left-of-about" />
      <div className="about-view flex flex-col items-center">
        <div className="about-summary flex flex-col items-center m-8">
          <div className="about-summary-header flex flex-row text-36 items-center text-cyberpink">
            r{emoji}b{emoji}b{emoji}ts
          </div>
          <div className="about-summary-lines flex flex-col items-center justify-center text-white md:m-4">
            <div className="summary-line">
              ten thousand robotic robots ownable through ethereum
            </div>
            <div className="summary-line">
              each constructed uniquely, in an algorithmic delirium</div>
            <div className="summary-line">
              while supplies last, claim your robobot for free!
            </div>
            <div className="summary-line">
              (though beware of the volatile eth network fees)
            </div>
            <div className="summary-line">
              use metamask to claim and trade our robo creations
            </div>
            <div className="summary-line">
              inspired by the cryptopunks project (no affiliations)
            </div>
          </div>
        </div>
        <hr className="about-separator w-full my-4" />
        <div className="guide-message max-w-lg text-center text-14 text-light m-8">
          {"For help with ethereum wallet setup and marketplace engagement, check out the "}
          <a
            href="https://cut-acrylic-01c.notion.site/Robobot-Owner-Manual-4e1287e96c8a42a1ae48b3e55f3cf782"
            className="guide-link text-cyberpink inline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Robobots Guide
          </a>
          {"."}
        </div>
        <hr className="about-separator w-full my-4" />
        <div className="form-container flex flex-col w-full max-w-lg my-8">
          <div className="form-description flex flex-row text-light text-14 text-center my-4">
            To send inquiries, feedback, or bug reports to the creators of this site,
            please using the following form. We'll do our best to review your message promptly.
          </div>
          <AboutForm />
        </div>
        <img className="w-24 h-24 mt-96 mb-4" src="robobot_letter.png" alt="robobots letter art" />
      </div>
      <div className="right-of-about" />
    </div>
  );
};

export const AboutForm: React.FC = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [isNotRobobotChecked, setIsNotRobobotChecked] = React.useState(false);
  const [isCaptchaCompleted, setCaptchaCompleted] = React.useState(false);
  const [isFormSubmitted, setFormSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const recaptchaCallback = (response: string) => {
    setCaptchaCompleted(true);
    const inputElement = document.querySelector<HTMLInputElement>
      ('input[name="g-recaptcha-response"]');
    if (inputElement) {
      inputElement.value = response;
    }
  };

  const recaptchaExpiredCallback = () => {
    setCaptchaCompleted(false);
  };

  window.recaptchaCallback = recaptchaCallback;
  window.recaptchaExpiredCallback = recaptchaExpiredCallback;

  const getRecaptchaResponse = () => grecaptcha?.getResponse() || '';

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNotRobobotChecked(event.target.checked);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage("");
    const formData = new FormData(event.currentTarget as HTMLFormElement);
    formData.set("g-recaptcha-response", getRecaptchaResponse());
    try {
      const response = await axios.post(
        "https://usebasin.com/f/cb03a9a46a33",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.status === 200) {
        setFormSubmitted(true);
        if (typeof grecaptcha !== 'undefined') {
          grecaptcha.reset(); // not sure if resetting the global variable is necessary
        }
      } else if (response.status === 400) {
        setErrorMessage("Please check for missing or incomplete fields and try again.");
      } else if (response.status === 500) {
        setErrorMessage("Form server unavailable, please try again later.");
      } else {
        setErrorMessage("Unknown error, please try again later or email robobotsart@gmail.com directly.");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Unknown error, please try again later or email robobotsart@gmail.com directly.");
    }
  };

  const isFormDisabled = () => {
    return name === "" || email === "" || message === "" || !isCaptchaCompleted;
  };

  return (
    <>
      {isFormSubmitted ? (
        <div className="flex flex-col items-center justify-center text-14 my-2 p-4 bg-back2 rounded">
          <div className="text-cybergreen text-center font-bold">Thank you! Your message was transmitted successfully.</div>
          <div className="text-light text-center">(You may refresh the page to send another message.)</div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="flex flex-col">
          <label htmlFor="name" className="email-form-label">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="bg-back2 rounded"
            required
          />

          <label htmlFor="email" className="email-form-label">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-back2 rounded"
            required
          />

          <label htmlFor="message" className="email-form-label">Message</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={10000}
            className="bg-back2 rounded"
            required
          ></textarea>

          <div className="flex items-center self-center my-4">
            <input
              type="checkbox"
              id="not-robobot"
              className="custom-checkbox"
              checked={isNotRobobotChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="not-robobot" className="ml-2 text-14">
              I'm not a robobot (we have to check)
            </label>
          </div>
          <div
            className={`g-recaptcha my-2 self-center ${isNotRobobotChecked ? "" : "hide-recaptcha"}`}
            data-sitekey="6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"
            data-callback="recaptchaCallback"
            data-expired-callback="recaptchaExpiredCallback"
          ></div>
          <input type="hidden" name="g-recaptcha-response" />
          <button
            type="submit"
            className={`self-center rounded my-2 ${isFormDisabled() ? "form-submit-disabled" : "form-submit"}`}
            disabled={isFormDisabled()}
          >
            Submit
          </button>
          {errorMessage &&
            <div className="flex flex-col items-center text-14 my-2 p-2 bg-back2 rounded">
              <div className="text-cyberorange text-center">{errorMessage}</div>
            </div>}
        </form>

      )}
    </>
  )
}