import React from "react";

const images = [
  { src: "/kitty_loading.gif", probability: 9 },
  { src: "/droid_loading.gif", probability: 23 },
  { src: "/karel_loading.gif", probability: 57 },
  { src: "/hero_loading.gif", probability: 109 },
  { src: "/killer_loading.gif", probability: 226 },
  { src: "/seeker_loading.gif", probability: 452 },
  { src: "/computer_loading.gif", probability: 804 },
  { src: "/hotbot_loading.gif", probability: 1543 },
  { src: "/betabot_loading.gif", probability: 2716 },
  { src: "/protobot_loading.gif", probability: 4061 },
];

const getRandomImage = () => {
  const totalProbability = images.reduce((sum, image) => sum + image.probability, 0);
  const random = Math.random() * totalProbability;

  let sum = 0;
  for (const image of images) {
    sum += image.probability;
    if (random <= sum) {
      return image.src;
    }
  }
};

export const LoadingScreen: React.FC = () => {
  return (
    <div className="relative h-full">
      <div className="absolute inset-0 bg-back1 flex items-center justify-center">
        <img className="h-350 w-350" src={getRandomImage()} alt="Loading..." />
      </div>
    </div>
  );
};
