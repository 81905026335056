// Date string should be in ISO8601 format
export const formatDate = (dateString: string, shorten: boolean) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  if (shorten) {
    return `${month}/${day}`;
  } else {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);

    return `${month}/${day}/${year} ${hours}:${minutes}`;
  }
};